import React, { FC } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { useBrandingContext } from '../contexts/BrandingContext'
import { useUserContext } from '../contexts/UserContext'
import { useNavigate } from 'react-router-dom'

interface Props {
  heading: string
  content: string[]
  headingMaxWidth?: string
  widget?: React.ReactNode
  subtext?: string
  linkText?: string
  linkHref?: string
}

const AnnouncementsPage: FC<Props> = (props) => {
  const { heading, content, headingMaxWidth, widget, subtext, linkText, linkHref } = props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()
  const { APP_LOGO_WORDMARK_URL, APP_NAME } = useBrandingContext()

  const onClickLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('announcements_page:logo_click', {
      url: window.location.href,
      heading: heading,
    })
    navigate('/')
  }

  return (
    <Box mx={['20px', '160px']}>
      <Box mt="40px" mb="160px">
        <a href={'/'} onClick={onClickLogo}>
          <img src={APP_LOGO_WORDMARK_URL} alt={`${APP_NAME} logo`} style={{ maxHeight: '35px' }} />
        </a>
      </Box>
      <Heading
        size="3xl"
        maxWidth={headingMaxWidth ?? '360px'}
        fontWeight="500"
        style={{ wordWrap: 'normal' }}
      >
        {heading}
      </Heading>
      <Box mt="40px" mb="80px">
        {content.map((paragraph, i) => (
          <Text key={i} fontSize="lg" mb="20px" maxWidth="620px">
            {paragraph}
          </Text>
        ))}
      </Box>
      {widget && <Box>{widget}</Box>}
      <Box mt="20px">
        {subtext && <Text fontSize="sm">{subtext}</Text>}
        {linkText && linkHref && (
          <Text as="u" fontSize="sm">
            <a href={linkHref} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default AnnouncementsPage
