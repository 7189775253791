import React, { FC, useCallback } from 'react'

import { Box } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { ProjectPageType, ProjectType } from '../../../models/saved_sessions.types'
import ArchivedProjectCardTitle from './ArchivedProjectCardTitle'
import ProjectPageView from '../projectsSidebar/ProjectPageView'
import { useUserContext } from '../../../contexts/UserContext'

const Container = styled(Box)`
  background: #f6f6f6;
  border-radius: 12px;
  padding: 12px 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #d5d5d5;

  &:hover {
    .hover-show-title-icons {
      opacity: 1;
    }

    background: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    h2 {
      color: #000;
    }
  }
`

const EmptyLabelContainer = styled.div`
  width: 100%;
  align-items: center;
  overflow: hidden;

  p {
    color: #a7a7a7;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

interface Props {
  project: ProjectType
  isProjectLimitReached: boolean
  handleDeleteProject: (id: string) => void
  handleUnarchiveProject: (id: string) => void
  handleProjectLimitError: () => void
}

const ArchivedProjectCard: FC<Props> = (props) => {
  const {
    project,
    isProjectLimitReached,
    handleDeleteProject,
    handleUnarchiveProject,
    handleProjectLimitError,
  } = props
  const { pages, id } = project
  const { captureAnalytics } = useUserContext()

  const handleClickPageTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, page: ProjectPageType) => {
      event.stopPropagation()
      captureAnalytics('archived_project_card:page_title_click', {
        ...page,
        project,
        isProjectLimitReached,
      })
    },
    [captureAnalytics, project, isProjectLimitReached],
  )

  const handleClickDeleteProjectIcon = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics('archived_project_card:delete_project_icon_click', {
        project,
        isProjectLimitReached,
      })

      handleDeleteProject(id)
    },
    [captureAnalytics, handleDeleteProject, id, isProjectLimitReached, project],
  )

  const handleClickUnarchiveProjectIcon = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics('archived_project_card:unarchive_project_icon_click', {
        project,
        isProjectLimitReached,
      })

      if (isProjectLimitReached) {
        handleProjectLimitError()
      } else {
        handleUnarchiveProject(id)
      }
    },
    [
      captureAnalytics,
      project,
      isProjectLimitReached,
      handleProjectLimitError,
      handleUnarchiveProject,
      id,
    ],
  )

  return (
    <Container>
      <ArchivedProjectCardTitle
        title={project.title}
        handleClickDeleteProjectIcon={handleClickDeleteProjectIcon}
        handleClickUnarchiveProjectIcon={handleClickUnarchiveProjectIcon}
      />
      <Box>
        {pages.length === 0 && (
          <EmptyLabelContainer>
            <p>{`This project is empty.`}</p>
          </EmptyLabelContainer>
        )}
        {pages.map((page) => {
          const handleClickTitle = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            return handleClickPageTitle(e, page)
          }
          return (
            <ProjectPageView
              key={page.id}
              title={page.title}
              url={page.url}
              favIconUrl={page.favicon_url}
              isDraggingOverThis={false}
              isDraggingThis={false}
              isDraggingDisabled={true}
              shouldForceHighlightTop={false}
              shouldForceHighlightBottom={false}
              handleClickIcon={handleClickTitle}
              handleClickTitle={handleClickTitle}
              handleClickBody={handleClickTitle}
            />
          )
        })}
      </Box>
    </Container>
  )
}

export default ArchivedProjectCard
