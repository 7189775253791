import React, { FC, useEffect, useRef } from 'react'
import { useField } from 'formik'
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'

interface CheckboxStaticOptionProps {
  value: string
  label: string
  name: string
}

const CheckboxStaticOption: FC<CheckboxStaticOptionProps> = (props) => {
  const { name, value, label } = props
  const [checkboxField, checkboxMeta] = useField({ name, value, type: 'checkbox' })
  const isChecked = Array.isArray(checkboxMeta.value) && checkboxMeta.value.includes(value)

  return (
    <Box
      as="label"
      style={{ display: 'flex', alignItems: 'center' }}
      gridColumnGap={'8px'}
      fontSize={'12px'}
      fontWeight={500}
      color={isChecked ? '#000' : '#585858'}
    >
      <input className="pretty-checkbox" type="checkbox" {...checkboxField} />
      {label}
    </Box>
  )
}

interface CheckboxInputOptionProps {
  placeholder: string
  parentName: string
  name: string
  value: string
}

const CheckboxInputOption: FC<CheckboxInputOptionProps> = (props) => {
  const { name, parentName, placeholder, value } = props
  const textInputRef = useRef<HTMLInputElement | null>(null)
  const checkboxInputRef = useRef<HTMLInputElement | null>(null)
  const [checkboxField, checkboxMeta] = useField({
    name: parentName,
    value,
    type: 'checkbox',
  })
  const isChecked = Array.isArray(checkboxMeta.value) && checkboxMeta.value.includes(value)
  const [inputField, inputMeta, inputHelper] = useField({ name, type: 'text' })
  const isInputFilled = !!inputMeta.value

  useEffect(() => {
    if (!isChecked) {
      inputHelper.setValue('')
    } else {
      textInputRef.current?.focus()
    }
  }, [inputHelper, isChecked])

  useEffect(() => {
    if (isInputFilled && !isChecked) {
      checkboxInputRef.current?.click()
    }
  }, [isChecked, isInputFilled])

  return (
    <Box
      as="label"
      style={{ display: 'flex', alignItems: 'center' }}
      gridColumnGap={'8px'}
      height={'24px'}
      color={isChecked ? '#000' : '#585858'}
    >
      <input
        ref={checkboxInputRef}
        className="pretty-checkbox"
        type="checkbox"
        {...checkboxField}
      />
      <Input
        ref={textInputRef}
        fontSize={'12px'}
        fontWeight={500}
        variant="flushed"
        placeholder={placeholder}
        height={'24px'}
        _focusVisible={{ borderColor: 'black' }}
        {...inputField}
      />
    </Box>
  )
}

export interface StaticOptionType {
  type: 'static'
  value: string
  label: string
}

export interface InputOptionType {
  type: 'input'
  name: string
  value: string
  placeholder: string
}

interface Props {
  name: string
  label: string
  options: (StaticOptionType | InputOptionType)[]
}

const CheckboxGroupFormBlock = (props: Props) => {
  const { name, label, options } = props
  const [, meta] = useField({ name })
  const { error, touched } = meta

  return (
    <FormControl id={name} isInvalid={!!error && !!touched}>
      <FormLabel
        id="checkbox-group"
        htmlFor={name}
        color={'#585858'}
        fontSize={'14px'}
        fontWeight={'500'}
        lineHeight={'22px'}
      >
        {label}
      </FormLabel>
      <div role="group" aria-labelledby="checkbox-group">
        <Stack ml="20px" gap={'8px'}>
          {options.map((option) => {
            if (option.type === 'input') {
              return (
                <CheckboxInputOption
                  key={option.value}
                  parentName={name}
                  name={option.name}
                  placeholder={option.placeholder}
                  value={option.value}
                />
              )
            } else {
              return (
                <CheckboxStaticOption
                  key={option.value}
                  name={name}
                  value={option.value}
                  label={option.label}
                />
              )
            }
          })}
        </Stack>
      </div>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default CheckboxGroupFormBlock
