import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, Collapse, Flex, Button, ToastId, UseToastOptions, useToast } from '@chakra-ui/react'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import debounce from 'lodash.debounce'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxSelector, useReduxDispatch } from '../../../redux/baseStore'
import {
  clearSearchResultsMetadata,
  updateNumSearchResults,
  selectSearchQueryValue,
  updateBackendSearchResults,
  loadMoreBackendSearchResults,
} from '../../../redux/searchSlice'
import {
  LazyOmniSearchType,
  useAppendTablistPagesMutation,
  useDeleteTablistPageMutation,
  useLazyOmnisearchQuery,
} from '../../../redux/services/skeema/tablist_pages.endpoints'

import SearchResultsLimitModal from '../search/SearchResultsLimitModal'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useDeleteOpenTabMutation } from '../../../webapp/redux/extension'
import InstantSaveToast from '../instantSave/InstantSaveToast'
import { selectOmnisearchResults } from '../../../redux/utils/advancedSelectors'
import PutasideTabController from '../PutasideTabList/PutasideTabController'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 16px;
`

const TableHeaderCell = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: #585858;
    line-height: 22px; /* 157.143% */
  }
`

const ColumnHeaderContainer = styled.div`
  margin-left: 16px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    color: #a7a7a7;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    &:last-child {
      margin-right: 174px;
    }
  }

  hr {
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
    margin: 4px 0px;
  }
`

const logSearchInputChangeDebounced = debounce(
  (captureAnalytics, queryValue: string, resultsLength: number) => {
    captureAnalytics('tablist:search_input_change', {
      queryValue,
      resultsLength,
    })
  },
  1000,
  {
    leading: false,
    trailing: true,
  },
)

const debouncedSearchCb = debounce(
  (callback: () => void) => {
    callback()
  },
  300,
  {
    leading: false,
    trailing: true,
  },
)

const TOAST_DURATION_MS = 8000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface PropTypes {
  children: React.ReactNode
}

const SearchResultsReplacementWrapper: FC<PropTypes> = (props) => {
  const { children } = props
  const { captureAnalytics, userInfo } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = React.useRef<ToastId | undefined>(undefined)

  const shouldIncludeOpenTabs = featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldIncludeOpenTabs]
  const IsPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]
  const shouldHaveUnlimitedInstantSave =
    userInfo?.is_premium || featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldHaveUnlimitedInstantSave]
  const isMoreSearchResultsEnabled = userInfo?.is_premium

  const instantSaveQuota = useReduxSelector((state) => state.user.instantSaveQuota)
  const queryValue = useReduxSelector(selectSearchQueryValue)
  const focusedIdx = useReduxSelector((state) => state.search.focusedIdx)
  const searchResults = useReduxSelector(selectOmnisearchResults)

  const numSearchResults = searchResults?.length
  const isSearchingActive = queryValue.trim().length > 0

  const [isSearchResultsLimitModalOpen, setIsSearchResultsLimitModalOpen] = useState<boolean>(false)

  const [appendTablistPages] = useAppendTablistPagesMutation()
  const [_deleteTablistPage] = useDeleteTablistPageMutation()
  const [_deleteOpenTab] = useDeleteOpenTabMutation()

  const deleteTablistPage = _deleteTablistPage as unknown as (params: {
    page: TablistPageType
    index: number
  }) => Promise<void>
  const deleteOpenTab = _deleteOpenTab as unknown as (params: { id: string }) => Promise<void>

  // Backend search related (load more button)
  const [triggerOmniSearchQuery] = useLazyOmnisearchQuery({ refetchOnFocus: false })
  const backendSearchRequestRef = useRef<ReturnType<LazyOmniSearchType[0]> | undefined>(undefined)

  // WAITING_ON_USER: Backend search isn't currently running (e.g. viewing results)
  // DEBOUNCE_LOADING:   User is typing, debounce is delaying search, show spinner instead of load more button
  // BACKEND_LOADING:    Backend search is running, waiting on results, show spinner instead of load more button
  // NO_MORE_RESULTS:    Backend search is complete, no more results, show message instead of load more button
  // ERROR:              Backend search errored, show error message
  const [backendSearchState, setBackendSearchState] = useState<
    'WAITING_ON_USER' | 'DEBOUNCE_LOADING' | 'BACKEND_LOADING' | 'NO_MORE_RESULTS' | 'ERROR'
  >('WAITING_ON_USER')
  const isSearchLoadMoreButtonShown =
    backendSearchState !== 'ERROR' && backendSearchState !== 'NO_MORE_RESULTS'
  const isSearchLoadMoreButtonLoading =
    backendSearchState === 'BACKEND_LOADING' || backendSearchState === 'DEBOUNCE_LOADING'

  const searchTablistPages = useCallback(
    (params: {
      query: string
      page?: number
      shouldDebounce: boolean
      shouldAppendResults?: boolean
    }) => {
      const { query, page = 1, shouldDebounce, shouldAppendResults = false } = params

      const callback = () => {
        if (backendSearchRequestRef.current) {
          backendSearchRequestRef.current.abort()
          backendSearchRequestRef.current = undefined
        }

        setBackendSearchState('BACKEND_LOADING')

        backendSearchRequestRef.current = triggerOmniSearchQuery({ query, page })

        backendSearchRequestRef.current
          .unwrap()
          .then((data) => {
            if (shouldAppendResults) {
              dispatch(loadMoreBackendSearchResults(data.results))
            } else {
              dispatch(updateBackendSearchResults(data.results))
            }

            if (data.does_more_exist) {
              setBackendSearchState('WAITING_ON_USER')
            } else {
              setBackendSearchState('NO_MORE_RESULTS')
            }
          })
          .catch((err) => {
            setBackendSearchState('ERROR')
            console.error(err)
          })
      }

      if (shouldDebounce) {
        setBackendSearchState('DEBOUNCE_LOADING')
        debouncedSearchCb(callback)
      } else {
        callback()
      }
    },
    [triggerOmniSearchQuery, dispatch],
  )

  const loadedSearchResultPages = useRef<number>(1)

  useEffect(() => {
    //Handle automatic backend search while query is changing
    if (queryValue.trim().length === 0) {
      return
    }

    loadedSearchResultPages.current = 1

    searchTablistPages({
      query: queryValue,
      shouldDebounce: true,
    })
  }, [queryValue, searchTablistPages])

  useEffect(() => {
    //Just logging the search input change
    const trimmedQuery = queryValue.trim()
    if (!searchResults || trimmedQuery === '') {
      return
    }
    logSearchInputChangeDebounced(captureAnalytics, trimmedQuery, searchResults.length)
  }, [captureAnalytics, queryValue, searchResults])

  useEffect(() => {
    dispatch(clearSearchResultsMetadata())
    if (isSearchingActive && numSearchResults) {
      dispatch(updateNumSearchResults(numSearchResults))
    }
  }, [isSearchingActive, numSearchResults, dispatch])

  const handleOnClickUpgrade = useCallback(() => {
    captureAnalytics('instant_save_toast:upgrade_click')
    navigate('/#pricing')
  }, [captureAnalytics, navigate])

  const saveAndCloseOpenTab = useCallback(
    async (params: { page: TablistPageType }) => {
      const { page } = params
      const id = page.id

      const isPaywallBlockingSave =
        IsPaywallEnabled && !shouldHaveUnlimitedInstantSave && (instantSaveQuota ?? 0) <= 0

      if (!isPaywallBlockingSave) {
        await appendTablistPages({ page, isManual: true })
          .unwrap()
          .then((res) => {
            if (res.ok) {
              deleteOpenTab({ id })
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }

      if (IsPaywallEnabled && !shouldHaveUnlimitedInstantSave) {
        const toastParams = {
          duration: TOAST_DURATION_MS,
          render: () => (
            <InstantSaveToast
              instantSaveQuota={instantSaveQuota}
              onClickUpgrade={handleOnClickUpgrade}
            />
          ),
          onCloseComplete: () => {
            toastIdRef.current = undefined
          },
        }
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastParams)
        } else {
          toastIdRef.current = toast({ position: 'bottom', ...toastParams })
        }
      }
    },
    [
      IsPaywallEnabled,
      appendTablistPages,
      deleteOpenTab,
      handleOnClickUpgrade,
      instantSaveQuota,
      shouldHaveUnlimitedInstantSave,
      toast,
    ],
  )

  useEffect(() => {
    // Update the toast if the instant save quota changes
    if (IsPaywallEnabled && toastIdRef.current) {
      const toastParams = {
        duration: TOAST_DURATION_MS,
        render: () => (
          <InstantSaveToast
            instantSaveQuota={instantSaveQuota}
            onClickUpgrade={handleOnClickUpgrade}
          />
        ),
      }
      toast.update(toastIdRef.current, toastParams)
    }
  }, [IsPaywallEnabled, handleOnClickUpgrade, instantSaveQuota, toast])

  const onClickShowMoreSearchResults = useCallback(() => {
    captureAnalytics('putaside_tab_list:show_more_search_results_click', {
      loadedSearchResultPages: loadedSearchResultPages.current,
    })

    loadedSearchResultPages.current += 1

    searchTablistPages({
      query: queryValue,
      page: loadedSearchResultPages.current,
      shouldDebounce: false,
      shouldAppendResults: true,
    })
  }, [captureAnalytics, searchTablistPages, queryValue])

  const handleSearchResultsLimitError = useCallback(() => {
    captureAnalytics('putaside_tab_list:search_results_limit_modal_show')
    setIsSearchResultsLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissSearchResultsLimitModal = () => {
    captureAnalytics('putaside_tab_list:search_results_limit_modal_dismiss_click')
    setIsSearchResultsLimitModalOpen(false)
  }

  const handleSubmitSearchResultsLimitModal = () => {
    captureAnalytics('putaside_tab_list:search_results_limit_modal_upgrade_click')
    setIsSearchResultsLimitModalOpen(false)
    navigate('/#pricing')
  }

  return (
    <>
      <Collapse
        key={`search-replacement-main-children-${!isSearchingActive}`} //This fixes an issue where both sections are left with display: none, but breaks the collapse animation
        style={{ overflow: 'unset' }}
        in={!isSearchingActive}
        animateOpacity
      >
        {children}
      </Collapse>
      <Collapse
        key={`search-replacement-search-results-${isSearchingActive}`} //This fixes an issue where both sections are left with display: none, but breaks the collapse animation
        style={{ overflow: 'unset' }}
        in={isSearchingActive}
        animateOpacity
      >
        <SectionContainer>
          <TableHeaderContainer>
            <TableHeaderCell className="saved-header">
              <h2>
                {isSearchLoadMoreButtonLoading
                  ? 'Searching...'
                  : `${(searchResults ?? []).length} ${
                      (searchResults ?? []).length === 1 ? 'result' : 'results'
                    }`}
              </h2>
            </TableHeaderCell>
          </TableHeaderContainer>

          {searchResults && searchResults.length === 0 && !isSearchLoadMoreButtonLoading && (
            <div className="flex-center" style={{ width: '100%' }}>
              <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
                No results found
              </Text>
            </div>
          )}

          {searchResults && searchResults.length > 0 && (
            <>
              <ColumnHeaderContainer>
                <div>
                  <h3>Tab title</h3>
                  <h3>Location</h3>
                </div>
                <hr />
              </ColumnHeaderContainer>
            </>
          )}

          <div>
            {searchResults?.map((t, idx) => {
              if (!shouldIncludeOpenTabs && t.entity_type === TABLIST_PAGE_ENUM.OPEN) {
                return <React.Fragment key={t.id} />
              }

              return (
                <PutasideTabController
                  key={`${t.entity_type}-${t.id}`}
                  id={t.id}
                  page={t}
                  shouldDisplayLocation={true}
                  showTimeString={false}
                  onDeleteTablistPage={deleteTablistPage}
                  onDeleteOpenTab={deleteOpenTab}
                  onSaveOpenTab={saveAndCloseOpenTab}
                  queryValue={queryValue}
                  titleHighlights={t.title_highlights}
                  index={idx}
                  isFocused={focusedIdx === idx}
                  numTotalResults={numSearchResults ?? 0}
                  areaName={TABLIST_AREA_NAME_ENUM.SearchResults}
                />
              )
            })}
          </div>

          {searchResults && searchResults.length > 0 && (
            <Flex w="100%" alignItems="center" justifyContent="flex-end">
              {isSearchLoadMoreButtonShown && (
                <Button
                  key="extra"
                  size="sm"
                  fontSize={12}
                  fontWeight={500}
                  borderRadius={'16px'}
                  mt={'8px'}
                  h="32px"
                  minH="32px"
                  w="100%"
                  px={2}
                  onClick={
                    isMoreSearchResultsEnabled
                      ? onClickShowMoreSearchResults
                      : handleSearchResultsLimitError
                  }
                  bg="#F6F6F6"
                  color="#585858"
                  _hover={{ bg: '#EBEBEB' }}
                  isLoading={isSearchLoadMoreButtonLoading}
                >
                  {`Show older results`}
                </Button>
              )}
              {!isSearchLoadMoreButtonShown && (
                <Text
                  fontSize={12}
                  fontWeight={500}
                  mt="16px"
                  w="100%"
                  px={2}
                  color="#A7A7A7"
                  textAlign="center"
                >
                  {backendSearchState === 'ERROR'
                    ? `Error loading more results, please refresh the page to try again`
                    : `You've reached the end of your search results`}
                </Text>
              )}
            </Flex>
          )}
        </SectionContainer>
      </Collapse>

      <SearchResultsLimitModal
        isOpen={isSearchResultsLimitModalOpen}
        onCancel={handleDismissSearchResultsLimitModal}
        onSubmit={handleSubmitSearchResultsLimitModal}
      />
    </>
  )
}

export default SearchResultsReplacementWrapper
