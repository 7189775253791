import React, { FC, useMemo } from 'react'
import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
} from '@chakra-ui/react'
import {
  PROJECT_PERMISSION_ENUM,
  ProjectPermissionsType,
} from '../../../models/saved_sessions.types'

const MAX_TITLE_LENGTH = 36

interface Props {
  title: string
  isShared: boolean
  isLoading: boolean
  isOpen: boolean
  onCancel: () => void
  onCopyLink: () => void
  handleChangeProjectPermission: (permission: ProjectPermissionsType) => void
}

const ProjectShareModal: FC<Props> = (props) => {
  const {
    title,
    isShared,
    isLoading,
    isOpen,
    onCancel,
    onCopyLink,
    handleChangeProjectPermission,
  } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeProjectPermission({
      target: 'ANONYMOUS',
      permission: event.target.checked
        ? PROJECT_PERMISSION_ENUM.VIEW
        : PROJECT_PERMISSION_ENUM.NOT_SHARED,
    })
  }

  const truncatedTitle = useMemo(() => {
    if (title.length > MAX_TITLE_LENGTH) {
      return title.slice(0, MAX_TITLE_LENGTH) + '...'
    }
    return title
  }, [title])

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCancel} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader>{`Share "${truncatedTitle}"`}</ModalHeader>
        <ModalBody>
          <Flex>
            <Text color="#585858" fontSize="16px" fontWeight={600} lineHeight="24px" mb="8px">
              Share with link
            </Text>
            {isLoading ? (
              <Flex justifyContent="center" my={4}>
                <Spinner size="md" color="blue.500" />
              </Flex>
            ) : (
              <Switch colorScheme="black" ml="auto" isChecked={isShared} onChange={handleChange} />
            )}
          </Flex>
          <Text fontSize={'16px'} color="#A7A7A7" fontWeight={400} lineHeight="24px">
            Anyone with the link can view this project
          </Text>
        </ModalBody>

        <ModalFooter mt="16px">
          <Button
            height={'36px'}
            size="md"
            variant="outline"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            color={'black'}
            border="1px solid #A7A7A7"
            fontWeight={500}
            _hover={{
              backgroundColor: '#EBEBEB',
            }}
            onClick={onCancel}
            autoFocus
          >
            Close
          </Button>
          <Button
            ml="8px"
            height={'36px'}
            size="md"
            variant="solid"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            backgroundColor={'black'}
            color={'white'}
            fontWeight={500}
            _hover={{
              backgroundColor: '#585858',
            }}
            _disabled={{
              backgroundColor: '#D5D5D5',
              color: '#ffffff',
            }}
            onClick={onCopyLink}
            isDisabled={!isShared}
            autoFocus
          >
            Copy project link
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ProjectShareModal
