import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}

const OnboardingTabGroupsDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        <>
          First, we saved your tab groups as{' '}
          <u
            style={{
              fontSize: 'inherit',
              fontWeight: 500,
              lineHeight: '40px',
              color: '#000',
              letterSpacing: '-1px',
            }}
          >
            Projects
          </u>
          . Projects keep related tabs together and close by.
        </>
      }
      description={`You can update or archive a Project anytime once you're done with onboarding.`}
      buttonText={`Next`}
      onButtonClick={onNext}
    />
  )
}
export default OnboardingTabGroupsDialog
