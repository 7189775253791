import React, { FC } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { APP_LOGO_WORDMARK_URL_SKIPPER } from '../constants'

interface Props {
  heading: string
  content: string[]
  headingMaxWidth?: string
  widget?: React.ReactNode
  subtext?: string
  linkText?: string
  linkHref?: string
  logoUrlOverride?: string
}

const MobileAnnouncementsPage: FC<Props> = (props) => {
  const { heading, content, headingMaxWidth, widget, subtext, linkText, linkHref } = props

  return (
    <Box mx={['20px', '160px']}>
      <Box mt="40px" mb="160px">
        <a href={'/'}>
          <img
            src={APP_LOGO_WORDMARK_URL_SKIPPER}
            alt={`Skipper logo`}
            style={{ maxHeight: '35px' }}
          />
        </a>
      </Box>
      <Heading
        size="3xl"
        maxWidth={headingMaxWidth ?? '360px'}
        fontWeight="500"
        style={{ wordWrap: 'normal' }}
      >
        {heading}
      </Heading>
      <Box mt="40px" mb="80px">
        {content.map((paragraph, i) => (
          <Text key={i} fontSize="lg" mb="20px" maxWidth="620px">
            {paragraph}
          </Text>
        ))}
      </Box>
      {widget && <Box>{widget}</Box>}
      <Box mt="20px">
        {subtext && <Text fontSize="sm">{subtext}</Text>}
        {linkText && linkHref && (
          <Text as="u" fontSize="sm">
            <a href={linkHref} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default MobileAnnouncementsPage
