import React, { FC, useRef } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { Formik, FormikProps } from 'formik'
import { Box } from '@chakra-ui/react'
import CheckboxGroupFormBlock, {
  InputOptionType,
  StaticOptionType,
} from '../../../components/forms/CheckboxGroupFormBlock'
import DropDownMenu from '../../../components/forms/DropDownMenu'
import { useUserContext } from '../../../contexts/UserContext'
import RadioGroupFormBlock from '../../../components/forms/RadioGroupFormBlock'
import InputFormBlock from '../../../components/forms/InputFormBlock'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'

interface SurveyContentType {
  gender: {
    name: string
    label: string
    placeholder: string
    options: { value: string; label: string }[]
  }
  businessOwner: {
    name: string
    label: string
    options: { value: string; label: string }[]
  }
  industry: {
    name: string
    label: string
    placeholder: string
    options: { value: string; label: string }[]
  }
  adhdChallenges: {
    name: string
    label: string
    options: StaticOptionType[]
  }
  distractions: {
    name: string
    label: string
    placeholder: string
  }
  referral: {
    name: string
    label: string
    options: { value: string; label: string }[]
  }
  discovery: {
    name: string
    label: string
    options: (StaticOptionType | InputOptionType)[]
  }
}

const SURVEY_CONTENT: SurveyContentType = {
  gender: {
    name: 'gender',
    label: '1. What is your gender?',
    placeholder: 'Select gender',
    options: [
      { value: 'female', label: 'Female' },
      { value: 'male', label: 'Male' },
      { value: 'non_binary', label: 'Non-binary/Third gender/Two-Spirit' },
      { value: 'prefer_not_to_say', label: 'Prefer not to say' },
      { value: 'other', label: 'Other...' },
    ],
  },
  businessOwner: {
    name: 'businessOwner',
    label: '2. Are you a business owner?',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },
  industry: {
    name: 'industry',
    label: '3. In what industry do you work?',
    placeholder: 'Select industry',
    options: [
      { value: 'administrative', label: 'Administrative' },
      { value: 'architecture_engineering', label: 'Architecture & Engineering' },
      { value: 'arts_entertainment_sports_media', label: 'Arts, Entertainment, Sports, and Media' },
      { value: 'business_financial_operations', label: 'Business & Financial Operations' },
      { value: 'cleaning_maintenance', label: 'Cleaning & Maintenance' },
      { value: 'community_social_services', label: 'Community and Social Services' },
      { value: 'computer_mathematics', label: 'Computer and Mathematics' },
      { value: 'construction_extraction', label: 'Construction and Extraction' },
      { value: 'education_library', label: 'Education and Library' },
      { value: 'farming_fishing_forestry', label: 'Farming, Fishing, and Forestry' },
      { value: 'food_preparation_services', label: 'Food Preparation and Services' },
      { value: 'government_employees', label: 'Government Employees' },
      { value: 'healthcare_medical', label: 'Healthcare and Medical' },
      { value: 'it_technical', label: 'IT and Technical' },
      { value: 'installation_repair', label: 'Installation and Repair' },
      { value: 'legal', label: 'Legal' },
      { value: 'life_physical_social_science', label: 'Life, Physical, and Social Science' },
      { value: 'management', label: 'Management' },
      { value: 'military', label: 'Military' },
      { value: 'nursing', label: 'Nursing' },
      { value: 'personal_care', label: 'Personal Care' },
      { value: 'production', label: 'Production' },
      { value: 'protection_services', label: 'Protection Services' },
      { value: 'retail', label: 'Retail' },
      { value: 'sales', label: 'Sales' },
      { value: 'temporary_seasonal', label: 'Temporary and Seasonal' },
      { value: 'transportation_moving', label: 'Transportation and Moving' },
      { value: 'other', label: 'Other...' },
    ],
  },
  adhdChallenges: {
    name: 'adhdChallenges',
    label: '4. Do you personally identify with any of the following?',
    options: [
      {
        type: 'static',
        value: 'diagnosed_adhd',
        label: 'I have been diagnosed with ADHD.',
      },
      {
        type: 'static',
        value: 'suspect_adhd',
        label: `I believe I may have ADHD but haven't been diagnosed.`,
      },
      {
        type: 'static',
        value: 'focus_challenges',
        label: `I experience challenges with focus or attention, but don't identify as having ADHD.`,
      },
      {
        type: 'static',
        value: 'no_challenges',
        label: 'I do not experience these challenges.',
      },
    ],
  },
  distractions: {
    name: 'distractions',
    label: '5. How do distractions or losing focus get in the way of things that matter to you?',
    placeholder: 'Your answer',
  },
  referral: {
    name: 'referral',
    label:
      '6. Have you ever started using a productivity tool after receiving a referral code or a direct link from a friend or colleague?',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },
  discovery: {
    name: 'discovery',
    label:
      '7. Where do you usually discover new tools, apps, or products to help with your work or productivity?',
    options: [
      { type: 'static', value: 'search_engines', label: 'Search engines (e.g., Google, Bing)' },
      {
        type: 'static',
        value: 'social_media',
        label: 'Social media (e.g., Instagram, Facebook, TikTok)',
      },
      {
        type: 'static',
        value: 'communities',
        label: 'Productivity or focus communities (e.g., Reddit, Caveday, ADHD-specific groups)',
      },
      {
        type: 'static',
        value: 'blogs',
        label: 'Blogs or newsletters (e.g., productivity blogs, tech reviews)',
      },
      {
        type: 'static',
        value: 'influencers',
        label: 'Recommendations from influencers or thought leaders',
      },
      { type: 'static', value: 'app_stores', label: 'App stores (e.g., Google Play, Apple Store)' },
      { type: 'static', value: 'friends', label: 'Recommendations from friends or colleagues' },
      { type: 'static', value: 'ads', label: 'Paid ads (e.g., Facebook ads, Google ads)' },
      { type: 'input', name: 'discovery_other', value: 'other', placeholder: 'Other' },
    ],
  },
}

interface FormikValuesType {
  gender: string
  businessOwner: string
  businessOwner_other: string
  industry: string
  adhdChallenges: string[]
  distractions: string
  referral: string
  discovery: string[]
  discovery_other: string
}

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingSurveyDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { captureAnalytics } = useUserContext()
  const formikRef = useRef<FormikProps<FormikValuesType>>(null)

  const bodyContent = (
    <Box
      className="scrollbars-always-visible"
      maxHeight="80vh"
      minHeight="300px"
      padding="4px"
      overflow="auto"
    >
      <Formik
        initialValues={{
          gender: '',
          businessOwner: '',
          businessOwner_other: '',
          industry: '',
          adhdChallenges: [],
          distractions: '',
          referral: '',
          discovery: [],
          discovery_other: '',
        }}
        onSubmit={(values) => {
          const payload = {
            survey_version: 4,
            surveyGender: values.gender,
            surveyBusinessOwner: values.businessOwner,
            surveyBusinessOwnerOther: values.businessOwner_other,
            surveyIndustry: values.industry,
            surveyAdhdChallenges: values.adhdChallenges,
            surveyDistractions: values.distractions,
            surveyReferral: values.referral,
            surveyDiscovery: values.discovery.map((el) => {
              const opt = SURVEY_CONTENT.discovery.options.find((o) => o.value === el)
              return opt?.type === 'static' ? opt.label : values.discovery_other
            }),
            surveyDiscoveryOther: values.discovery_other,
          }
          console.log('payload', payload)

          window.localStorage.setItem(
            WEBAPP_LOCAL_STORAGE_KEYS.IsSurveyNotificationVisible,
            'false',
          )
          captureAnalytics('onboarding:submit_survey', payload)
          onNext()
        }}
        innerRef={formikRef}
      >
        {(formikProps: FormikProps<FormikValuesType>) => {
          const { handleSubmit } = formikProps

          return (
            <form onSubmit={handleSubmit}>
              <Box mb="16px">
                <DropDownMenu
                  name={SURVEY_CONTENT.gender.name}
                  label={SURVEY_CONTENT.gender.label}
                  placeholder={SURVEY_CONTENT.gender.placeholder}
                  options={SURVEY_CONTENT.gender.options}
                />
              </Box>

              <Box mb="16px">
                <RadioGroupFormBlock
                  name={SURVEY_CONTENT.businessOwner.name}
                  label={SURVEY_CONTENT.businessOwner.label}
                  options={SURVEY_CONTENT.businessOwner.options}
                  shouldIncludeInputOption={true}
                />
              </Box>

              <Box mb="16px">
                <DropDownMenu
                  name={SURVEY_CONTENT.industry.name}
                  label={SURVEY_CONTENT.industry.label}
                  placeholder={SURVEY_CONTENT.industry.placeholder}
                  options={SURVEY_CONTENT.industry.options}
                />
              </Box>

              <Box mb="16px">
                <CheckboxGroupFormBlock
                  name={SURVEY_CONTENT.adhdChallenges.name}
                  label={SURVEY_CONTENT.adhdChallenges.label}
                  options={SURVEY_CONTENT.adhdChallenges.options}
                />
              </Box>

              <Box mb="16px">
                <InputFormBlock
                  name={SURVEY_CONTENT.distractions.name}
                  label={SURVEY_CONTENT.distractions.label}
                  placeholder={SURVEY_CONTENT.distractions.placeholder}
                />
              </Box>

              <Box mb="16px">
                <RadioGroupFormBlock
                  name={SURVEY_CONTENT.referral.name}
                  label={SURVEY_CONTENT.referral.label}
                  options={SURVEY_CONTENT.referral.options}
                  shouldIncludeInputOption={false}
                />
              </Box>

              <Box mb="16px">
                <CheckboxGroupFormBlock
                  name={SURVEY_CONTENT.discovery.name}
                  label={SURVEY_CONTENT.discovery.label}
                  options={SURVEY_CONTENT.discovery.options}
                />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Box>
  )

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={'Lastly, tell us a bit about yourself.'}
      description={`We'd love to get to know you better! Your answers will help us tailor Skipper to your needs and improve your experience. Thanks for sharing!`}
      bodyContent={bodyContent}
      buttonText={`Submit`}
      onButtonClick={() => {
        formikRef.current?.handleSubmit()
      }}
      delayButtonActivation={true}
    />
  )
}
export default OnboardingSurveyDialog
