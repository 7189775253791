import React, { FC, useState } from 'react'
import {
  Text,
  Box,
  Button,
  Link,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  forwardRef,
  Stack,
  Flex,
} from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useUserContext } from '../../contexts/UserContext'
import { MdMenu, MdOutlinePerson, MdOutlineThumbsUpDown } from 'react-icons/md'
import { CiSettings } from 'react-icons/ci'
import { FiBookOpen } from 'react-icons/fi'
import { GoSignOut } from 'react-icons/go'
import PaymentPlanBadge from './payment/PaymentPlanBadge'
import PlansSVG from '../../utils/svgs/PlansSVG'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext'
import { IoMdArrowBack } from 'react-icons/io'
import { useBrandingContext } from '../../contexts/BrandingContext'

export enum MENU_LEVEL_ENUM {
  MAIN = 'MAIN',
  ACCOUNT = 'ACCOUNT',
}

const ChakraMenuContainer = styled.div`
  display: flex;

  .chakra-menu-button {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .chakra-menu-list {
    position: relative;
    display: flex;
    flex-direction: column;

    border-radius: 16px;
    background: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    width: 240px;
    padding: 8px;
    gap: 4px;
  }

  .chakra-menu-item {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 10px;

    p {
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }

    svg {
      margin-right: 8px;
      color: #585858;
    }

    &:hover {
      background: #ebebeb;
      text-decoration: none;

      svg {
        color: #585858;
      }

      p {
        background: #ebebeb;
      }
    }

    &:focus {
      background: #ebebeb;
      text-decoration: none;
    }
  }
`

const HorizontalLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const SubNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 1500px) {
    .hamburger-menu {
      display: none;
    }
    .horizontal-menu {
      display: flex;
    }
  }

  @media (max-width: 1500px) {
    .hamburger-menu {
      display: flex;
    }
    .horizontal-menu {
      display: none;
    }
  }
`

const NavLinkContainer = styled.div`
  cursor: pointer;

  p {
    color: #585858;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
  }
`

const AccountButton = forwardRef((props, ref) => {
  return (
    <NavLinkContainer>
      <Button as="p" variant="ghost" _hover={{ bg: '#EBEBEB' }} ref={ref} {...props}>
        My Account
      </Button>
    </NavLinkContainer>
  )
})

const SubNavButtons: FC = () => {
  const navigate = useNavigate()

  const { authHeader } = useAuthContext()
  const { userInfo, captureAnalytics, signOut } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const { HOW_TO_URL, FEEDBACK_FORM_URL } = useBrandingContext()

  const isPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]
  const isConfigurable = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsConfigurable]

  const [menuLevel, setMenuLevel] = useState<MENU_LEVEL_ENUM>(MENU_LEVEL_ENUM.MAIN)

  const isPremium = !!userInfo?.is_premium

  const handleClickNavbarMenuIcon = () => {
    captureAnalytics('navbar:hamburger_menu_click')
  }

  const handleBackClick = () => {
    captureAnalytics('navbar:hamburger_submenu_back_click')
    setMenuLevel(MENU_LEVEL_ENUM.MAIN)
  }

  const handleClickAccountMenuItem = () => {
    captureAnalytics('navbar:account_submenu_expand_click')
    setMenuLevel(MENU_LEVEL_ENUM.ACCOUNT)
  }

  const handleClickAccountButton = () => {
    captureAnalytics('navbar:account_menu_click')
  }

  const onClickGetStarted = () => {
    captureAnalytics('navbar:how_to')
  }

  const onClickShareFeedback = () => {
    captureAnalytics('navbar:share_feedback')
  }

  const onClickMyPlan = () => {
    navigate('/saved#pricing')
    captureAnalytics('navbar:my_plan_click', { isPremium })
  }

  const onClickSettings = () => {
    navigate('/preferences')
    captureAnalytics('navbar:settings_click')
  }

  const onClickSignOut = async () => {
    await signOut()
    captureAnalytics('navbar:sign_out')
  }

  if (!authHeader) {
    return null
  }

  const accountMenuItems = (
    <>
      <Stack gap="8px" p="12px">
        {isPaywallEnabled && <PaymentPlanBadge plan={isPremium ? 'pro' : 'basic'} />}
        <Text fontSize="12px" fontWeight="500" color="#A7A7A7">
          {userInfo?.email}
        </Text>
      </Stack>
      <Divider color="#D5D5D5" />
      {isPaywallEnabled && (
        <MenuItem className="chakra-menu-item" onClick={onClickMyPlan}>
          <PlansSVG />
          <p>Manage Plan</p>
        </MenuItem>
      )}
      {isConfigurable && (
        <MenuItem className="chakra-menu-item" onClick={onClickSettings}>
          <CiSettings strokeWidth="0.3px" />
          <p>Settings</p>
        </MenuItem>
      )}
      <MenuItem className="chakra-menu-item" onClick={onClickSignOut}>
        <GoSignOut strokeWidth="0.3px" />
        <p>Sign Out</p>
      </MenuItem>
    </>
  )

  return (
    <SubNavContainer>
      <ChakraMenuContainer className="hamburger-menu">
        <Menu onClose={handleBackClick}>
          <MenuButton
            className="chakra-menu-button"
            as={IconButton}
            aria-label="Options"
            icon={<MdMenu />}
            onClick={handleClickNavbarMenuIcon}
            background="white"
          />
          <MenuList className="chakra-menu-list" zIndex={12}>
            <Box display={menuLevel === MENU_LEVEL_ENUM.MAIN ? 'block' : 'none'}>
              <MenuItem
                className="chakra-menu-item"
                as={Link}
                href={HOW_TO_URL}
                onClick={onClickGetStarted}
                isExternal
              >
                <FiBookOpen />
                <p>Get Started</p>
              </MenuItem>
              <MenuItem
                className="chakra-menu-item"
                as={Link}
                href={FEEDBACK_FORM_URL}
                onClick={onClickShareFeedback}
                isExternal
              >
                <MdOutlineThumbsUpDown />
                <p>Share Feedback</p>
              </MenuItem>
              <MenuItem
                className="chakra-menu-item"
                onClick={handleClickAccountMenuItem}
                closeOnSelect={false}
              >
                <MdOutlinePerson />
                <p>My Account</p>
              </MenuItem>
            </Box>
            <Box display={menuLevel === MENU_LEVEL_ENUM.ACCOUNT ? 'block' : 'none'}>
              <Button
                onClick={handleBackClick}
                h="28px"
                w="70px"
                background="white"
                _hover={{ background: 'white' }}
                color="#585858"
                display="flex"
                alignItems="center"
                p="4px 12px 0px 4px"
              >
                <Flex alignItems={'center'}>
                  <Flex alignItems="center" justifyContent="center" w="24px" h="24px">
                    <IoMdArrowBack />
                  </Flex>
                  <Text color="#585858" fontWeight="500" fontSize="12px">
                    Back
                  </Text>
                </Flex>
              </Button>
              {accountMenuItems}
            </Box>
          </MenuList>
        </Menu>
        <span
          style={{
            height: '100%',
            width: '24px',
            flex: '0 1 auto',
          }}
        />
      </ChakraMenuContainer>

      <HorizontalLinkContainer className="horizontal-menu">
        <NavLinkContainer>
          <Link href={HOW_TO_URL} isExternal onClick={onClickGetStarted}>
            <Button as="p" variant="ghost" _hover={{ bg: '#EBEBEB' }}>
              Get Started
            </Button>
          </Link>
        </NavLinkContainer>

        <NavLinkContainer>
          <Link href={FEEDBACK_FORM_URL} isExternal onClick={onClickShareFeedback}>
            <Button as="p" variant="ghost" _hover={{ bg: '#EBEBEB' }}>
              Share Feedback
            </Button>
          </Link>
        </NavLinkContainer>

        <ChakraMenuContainer>
          <Menu>
            <MenuButton as={AccountButton} onClick={handleClickAccountButton} />
            <MenuList className="chakra-menu-list" zIndex={12}>
              {accountMenuItems}
            </MenuList>
          </Menu>
        </ChakraMenuContainer>
        <span
          style={{
            height: '100%',
            width: '44px',
            flex: '0 1 auto',
          }}
        />
      </HorizontalLinkContainer>
    </SubNavContainer>
  )
}

export default SubNavButtons
