import React, { FC } from 'react'
import { Tooltip, IconButton, Heading, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { LuUndo2 } from 'react-icons/lu'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 6px;

  & > h2 {
    color: #585858;
    transition: color 0.2s;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
  }
`

const HoverButtonsContainer = styled.div`
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: #f2f3fe;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff)';
  }
`

interface Props {
  title: string
  timestamp: string
  handleUndoClick: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const HistoryItemTitle: FC<Props> = (props) => {
  const { title, timestamp, handleUndoClick } = props

  return (
    <TitleContainer>
      <Heading ml="18px" as="h2">
        {title}
      </Heading>

      <Text color={'#A7A7A7'} fontSize={'12px'} fontWeight={500} ml="8px" lineHeight={'22px'}>
        {timestamp}
      </Text>

      <HoverButtonsContainer className="hover-show-title-icons">
        <Tooltip label={`Undo`} placement="top">
          <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
            <IconButton
              variant="outline"
              border="none"
              aria-label={`Open all pages`}
              as="a"
              icon={<LuUndo2 color="#585858" />}
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{ background: 'inherit' }}
              background={'#F6F6F6'}
              onClick={handleUndoClick}
            />
          </span>
        </Tooltip>
      </HoverButtonsContainer>
    </TitleContainer>
  )
}

export default HistoryItemTitle
