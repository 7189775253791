import React, { FC, ReactElement } from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import Portal from '../widgets/Portal'

interface Props {
  text: string
  buttonText: string
  buttonOnClick: () => void
  buttonIcon?: ReactElement
}

const StaticBanner: FC<Props> = (props) => {
  const { text, buttonText, buttonIcon, buttonOnClick } = props

  return (
    <Portal>
      <Box position="fixed" top="22px" left="50%" transform="translate(-50%, 0)">
        <Flex
          bg="#CCE3F9"
          color="#0071E3"
          borderRadius="40px"
          borderColor="#CCE3F9"
          border="1px solid #A3CCF5"
          boxShadow="0px 0px 4px 0px #0000000A, 0px 8px 16px 0px #00000014"
          mb="32px"
          gap="40px"
          p="8px 8px 8px 24px"
          alignItems="center"
        >
          <Text className="is-ellipsis-2" fontSize="md" fontWeight={500}>
            {text}
          </Text>
          <Button
            bg="#0071E3"
            color="white"
            borderRadius="100px"
            flexShrink={0}
            _hover={{ bg: '#0071E3' }}
            onClick={buttonOnClick}
            rightIcon={buttonIcon && React.cloneElement(buttonIcon, { style: { flexShrink: 0 } })}
          >
            <Text fontSize="md" fontWeight={500} ml={2} mr={2}>
              {buttonText}
            </Text>
          </Button>
        </Flex>
      </Box>
    </Portal>
  )
}

export default StaticBanner
