import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'
import PutasideTabView from './PutasideTabView'
import { DND_ITEM_ENUM, DnDItemTablistPagePayload } from '../../../models/dnd.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { useReduxDispatch } from '../../../redux/baseStore'
import { setIsTablistPageDragging } from '../../../redux/dndSlice'
import { shouldOpenInNewTab } from '../../../utils/utils'
import { MoveTablistPageInfoType } from '../moveItemMenu/moveItemMenu.types'

interface PropTypes {
  id: string
  page: TablistPageType
  queryValue: string
  index: number
  numTotalResults: number
  areaName: TABLIST_AREA_NAME_ENUM
  shouldDisplayLocation?: boolean
  shouldShowPinIconForCheckbox?: boolean
  onDeleteTablistPage?: (params: { page: TablistPageType; index: number }) => Promise<void>
  onDeleteOpenTab?: (params: { id: string }) => Promise<void>
  onSaveOpenTab?: (params: { page: TablistPageType }) => Promise<void>
  onOpenMoveMenu?: (params: { pageInfo: MoveTablistPageInfoType }) => void
  onSelected?: (pageId: string, selected: boolean, shiftKey: boolean) => void
  isChecked?: boolean
  isCheckboxShown?: boolean
  isFocused?: boolean
  isAppFiltered?: boolean
  isToReadFiltered?: boolean
  isManualFiltered?: boolean
  showOpenTabIcon?: boolean
  titleHighlights?: [number, number][]
  showTimeString?: boolean
  showActionIcons?: boolean
  isSelectionDisabled?: boolean
  isHoverDisabled?: boolean
  isTitleClickDisabled?: boolean
  isDraggingDisabled?: boolean
  extraAnalyticsProps?: Record<string, unknown>
  spacingVariant?: 'default' | 'saved_for_later'
}

const PutasideTabController: FC<PropTypes> = (props) => {
  const {
    id,
    page,
    onDeleteTablistPage,
    onDeleteOpenTab,
    onSaveOpenTab,
    onOpenMoveMenu,
    onSelected,
    titleHighlights,
    queryValue,
    index,
    numTotalResults,
    shouldDisplayLocation = false,
    isChecked = false,
    isCheckboxShown = false,
    isFocused = false,
    isAppFiltered = false,
    isToReadFiltered = false,
    isManualFiltered = false,
    showOpenTabIcon = false,
    isSelectionDisabled = false,
    isHoverDisabled = false,
    isTitleClickDisabled = false,
    isDraggingDisabled = false,
    showTimeString = true,
    showActionIcons = true,
    shouldShowPinIconForCheckbox = false,
    spacingVariant = 'default',
    areaName,
    extraAnalyticsProps = {},
  } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const dispatch = useReduxDispatch()
  const tabContainerRef = useRef<HTMLDivElement | null>(null)
  const componentName = `tablist_${areaName}`

  useEffect(() => {
    if (isFocused && tabContainerRef.current) {
      tabContainerRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [isFocused])

  const handleClickCheckbox = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (isSelectionDisabled || !isCheckboxShown || !onSelected) {
        return
      }

      captureAnalytics(`${componentName}:tab_checkbox_toggle`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        isChecked: !isChecked,
        ...extraAnalyticsProps,
      })

      onSelected(id, !isChecked, event.shiftKey)
    },
    [
      extraAnalyticsProps,
      isSelectionDisabled,
      isCheckboxShown,
      componentName,
      captureAnalytics,
      page,
      queryValue,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      index,
      numTotalResults,
      isChecked,
      onSelected,
      id,
    ],
  )

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics(`${componentName}:tab_title_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      if (page.entity_type === TABLIST_PAGE_ENUM.OPEN) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SWITCH_TO_TAB, {
          tabId: parseInt(page.id),
          windowId: page.window_id,
        })
      } else {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [page.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      }
    },
    [
      extraAnalyticsProps,
      componentName,
      index,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      numTotalResults,
      page,
      captureAnalytics,
      queryValue,
    ],
  )

  const handleClickDeleteIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type) {
        return
      }

      captureAnalytics(`${componentName}:delete_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        isAppFiltered,
        isToReadFiltered,
        isManualFiltered,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      if (page.entity_type === TABLIST_PAGE_ENUM.OPEN && !!onDeleteOpenTab) {
        await onDeleteOpenTab({ id })
      } else if (onDeleteTablistPage) {
        await onDeleteTablistPage({ page, index })
      }
    },
    [
      page,
      captureAnalytics,
      componentName,
      queryValue,
      isAppFiltered,
      isToReadFiltered,
      isManualFiltered,
      index,
      numTotalResults,
      extraAnalyticsProps,
      onDeleteOpenTab,
      onDeleteTablistPage,
      id,
    ],
  )

  const handleClickSaveIcon = useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (!page.entity_type || page.entity_type !== TABLIST_PAGE_ENUM.OPEN || !onSaveOpenTab) {
        return
      }

      captureAnalytics(`${componentName}:save_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,
        ...extraAnalyticsProps,
      })

      await onSaveOpenTab({ page })
    },
    [
      extraAnalyticsProps,
      page,
      onSaveOpenTab,
      queryValue,
      index,
      numTotalResults,
      componentName,
      captureAnalytics,
    ],
  )

  const handleClickMoveIcon = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (
        !page.entity_type ||
        page.entity_type !== TABLIST_PAGE_ENUM.RECENTLY_USED ||
        !onOpenMoveMenu
      ) {
        return
      }

      captureAnalytics(`${componentName}:move_click`, {
        title: page.title,
        url: page.url,
        location: page.location,
        entityType: page.entity_type,
        queryValue,
        index,
        numTotalResults,

        ...extraAnalyticsProps,
      })

      onOpenMoveMenu({ pageInfo: { type: 'TABLIST_PAGE', page, index } })
    },
    [
      page,
      onOpenMoveMenu,
      captureAnalytics,
      componentName,
      queryValue,
      index,
      numTotalResults,
      extraAnalyticsProps,
    ],
  )

  const [{ isDraggingThis }, connectDragSource] = useDrag(
    () => ({
      type: DND_ITEM_ENUM.TABLIST_PAGE,
      collect: (monitor) => ({
        isDraggingThis: monitor.isDragging(),
      }),
      canDrag: () => !isDraggingDisabled,
      end: () => dispatch(setIsTablistPageDragging(false)),
      item: () => {
        dispatch(setIsTablistPageDragging(true))
        const payload: DnDItemTablistPagePayload = {
          type: DND_ITEM_ENUM.TABLIST_PAGE,
          page,
          index,
        }
        return payload
      },
    }),
    [id, page, dispatch, isDraggingDisabled, index],
  )

  return (
    <PutasideTabView
      location={page.location}
      entityType={page.entity_type}
      title={page.title ?? 'Untitled Page'}
      favIconUrl={page.favicon_url}
      lastAccessDateTime={page.last_access_datetime_utc_iso}
      showTimeString={showTimeString}
      titleHighlights={titleHighlights}
      isTitleClickDisabled={isTitleClickDisabled}
      showOpenTabIcon={showOpenTabIcon}
      isPinnedTab={page.is_pinned}
      handleClickCheckbox={handleClickCheckbox}
      handleClickFavIcon={handleClickCheckbox}
      handleClickDragIcon={handleClickCheckbox}
      handleClickDeleteIcon={
        !onDeleteOpenTab && !onDeleteTablistPage
          ? undefined
          : [TABLIST_PAGE_ENUM.OPEN, TABLIST_PAGE_ENUM.RECENTLY_USED].includes(page.entity_type)
            ? handleClickDeleteIcon
            : undefined
      }
      handleClickSaveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN && onSaveOpenTab
          ? handleClickSaveIcon
          : undefined
      }
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickCheckbox}
      isChecked={isChecked}
      isFocused={isFocused}
      isSelectionDisabled={isSelectionDisabled}
      isHoverDisabled={isHoverDisabled}
      isSelectionDisabledTooltipLabel={`You cannot select more than ${projectConfig.maxNumProjectsPages} pages`}
      isCheckboxShown={isCheckboxShown}
      isDraggingThis={isDraggingThis}
      isDraggingDisabled={isDraggingDisabled}
      connectDnD={!isDraggingDisabled ? connectDragSource : undefined}
      tabContainerRef={tabContainerRef}
      moveIconTooltip={'Move'}
      handleClickMoveIcon={
        page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED && onOpenMoveMenu
          ? handleClickMoveIcon
          : undefined
      }
      titleToolTip={page.entity_type === TABLIST_PAGE_ENUM.OPEN ? 'Go to Tab' : page.url}
      deleteIconToolTip={
        page.entity_type === TABLIST_PAGE_ENUM.OPEN
          ? 'Close'
          : page.entity_type === TABLIST_PAGE_ENUM.RECENTLY_USED
            ? 'Remove'
            : undefined
      }
      saveIconTooltip={onSaveOpenTab ? 'Save & close' : undefined}
      showActionIcons={showActionIcons}
      shouldShowPinIconForCheckbox={shouldShowPinIconForCheckbox}
      shouldDisplayLocation={shouldDisplayLocation}
      spacingVariant={spacingVariant}
    />
  )
}

export default React.memo(PutasideTabController)
