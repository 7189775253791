import { WEBAPP_LOCAL_STORAGE_KEYS } from '../models/browserStorage.types'

const ROTATING_EMOJIS = ['👽', '🏔️', '🌵', '🏊', '🎨', '🦄', '🛳️', '🚀', '🎨', '🐨']
const TITLE_PREFIX = 'Project'

export const getPlaceholderProjectTitle = (customEmoji?: string): string => {
  let emoji = customEmoji

  if (!customEmoji) {
    const countString: string | null = window.localStorage.getItem(
      WEBAPP_LOCAL_STORAGE_KEYS.PlaceholderSessionTitleCount,
    )
    const count = parseInt(countString ?? '0')
    const idx = count % ROTATING_EMOJIS.length
    emoji = ROTATING_EMOJIS[idx]
    window.localStorage.setItem(
      WEBAPP_LOCAL_STORAGE_KEYS.PlaceholderSessionTitleCount,
      String(count + 1),
    )
  }

  const dateStr = new Date().toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  return `${emoji ?? ''} ${TITLE_PREFIX} ${dateStr}`.trim()
}

export const adjustProjectTitleForDuplicates = (titles: string[], baseTitle: string): string => {
  let counter = 0
  let duplicateCountStr = ''
  let title = baseTitle

  while (
    titles.find(
      (t) => t.substring(t.indexOf(TITLE_PREFIX)) === title.substring(title.indexOf(TITLE_PREFIX)),
    )
  ) {
    counter += 1
    duplicateCountStr = ` (${counter})`
    title = `${baseTitle}${duplicateCountStr}`
  }

  return title
}
