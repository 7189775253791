import { createSlice } from '@reduxjs/toolkit'
import { TablistPageType } from '../models/tablist_pages.types'
import { ReduxStateType } from './baseStore'

export interface TablistSliceStateType {
  savedPages: TablistPageType[] | undefined
}

const initialState: TablistSliceStateType = {
  savedPages: undefined,
}

export const tablistSlice = createSlice({
  name: 'tablist',
  initialState,
  reducers: {
    loadOlderSavedPages: (
      state,
      action: {
        payload: TablistPageType[]
      },
    ) => {
      state.savedPages = state.savedPages ? state.savedPages.concat(action.payload) : action.payload
    },
    updateMostRecentSavedPages: (
      state,
      action: {
        payload: TablistPageType[]
      },
    ) => {
      if (!state.savedPages) {
        state.savedPages = action.payload
        return
      }

      const updatedPages = action.payload

      if (updatedPages.length > 0) {
        const oldestUpdatedPageTimestamp =
          updatedPages[updatedPages.length - 1].last_access_timestamp_ms

        let numElementsToReplace = 0
        let i = 0
        while (
          i < state.savedPages.length &&
          state.savedPages[i].last_access_timestamp_ms >= oldestUpdatedPageTimestamp
        ) {
          numElementsToReplace = i + 1
          i++
        }

        state.savedPages.splice(0, numElementsToReplace, ...updatedPages)
      }
    },
    savePage: (
      state,
      action: {
        payload: {
          page: TablistPageType
          index?: number
        }
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const { page, index = 0 } = action.payload
      state.savedPages.splice(index, 0, page)
    },
    deletePage: (
      state,
      action: {
        payload: string
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const id = action.payload
      const idx = state.savedPages.findIndex((p) => p.id === id)
      if (idx !== -1) {
        state.savedPages.splice(idx, 1)
      }
    },
    replacePage: (
      state,
      action: {
        payload: {
          id: string
          newPage: TablistPageType
        }
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const { id, newPage } = action.payload
      const idx = state.savedPages.findIndex((p) => p.id === id)
      if (idx !== -1) {
        state.savedPages.splice(idx, 1, newPage)
      }
    },
  },
})

export const selectTablistSavedPages = (state: ReduxStateType) => state.tablist.savedPages

export const {
  loadOlderSavedPages,
  updateMostRecentSavedPages,
  savePage,
  deletePage,
  replacePage,
} = tablistSlice.actions

export default tablistSlice.reducer
