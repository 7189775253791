import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import styled from 'styled-components'
import { isMac } from '../../../utils/utils'
import { useBrandingContext } from '../../../contexts/BrandingContext'

const cmdOrCtrl = isMac() ? 'Cmd' : 'Ctrl'

const KeyIcon = styled.span`
  font-size: 12px;
  font-weight: 600;
  background-color: rgb(78, 78, 78);
  color: white;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px 6px;
`

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingTablistDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { APP_NAME } = useBrandingContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  useEffect(() => {
    // Prevents bug where recently closed tabs are briefly included in the open count
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isLoading])

  const heading =
    openTabPages && openTabPages.length === 0
      ? `Nice! Down to just the ${APP_NAME} tab. Automatic Save will automatically ensure your browser stays clean.`
      : `Nice! Down to just ${APP_NAME} and ${openTabPages?.length ?? 'a few'} open tab${
          (openTabPages?.length ?? 2) > 1 ? 's' : ''
        }. Automatic Save will automatically ensure your browser stays clean.`

  const description = (
    <>
      {`Don't worry. You can access your saved tabs anytime by clicking the ${APP_NAME} tab on the tab bar or pressing`}
      <KeyIcon style={{ marginLeft: '6px' }}>{cmdOrCtrl}</KeyIcon>
      <KeyIcon>1</KeyIcon>
    </>
  )

  const onButtonClick = () => {
    onNext()
  }

  return (
    <OnboardingStepTemplate
      isCardLoading={isLoading}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={heading}
      description={description}
      buttonText={`Okay`}
      onButtonClick={onButtonClick}
    />
  )
}
export default OnboardingTablistDialog
