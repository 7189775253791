import React, { FC, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useUserContext } from '../../contexts/UserContext'
import SettingsForm from './SettingsForm'

const SettingsMainContent: FC = () => {
  const navigate = useNavigate()
  const { isDefault, featureFlags } = useFeatureFlagContext()
  const isInternal = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const isConfigurable = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsConfigurable]
  const { captureAnalytics } = useUserContext()

  useEffect(() => {
    captureAnalytics('settings_page:page_view')
  }, [captureAnalytics])

  useEffect(() => {
    if (!isDefault && !isConfigurable) {
      navigate('/not-found')
    }
  }, [isConfigurable, isDefault, navigate])

  return (
    <Box
      p="24px"
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box bg="#F6F6F6" borderRadius="16px" p="24px">
        <SettingsForm isInternal={isInternal} />
      </Box>
    </Box>
  )
}

export default SettingsMainContent
