import React, { FC, useEffect } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { FormikProvider, useFormik } from 'formik'
import { useUserContext } from '../../../contexts/UserContext'
import { ExtensionSettingsType } from '../../../extension/utils/settingsUtils'
import { SETTINGS_FORM_FIELD_NAMES } from '../settings/SettingsForm'
import OnboardingAutoSaveSettingsFormContent from './OnboardingAutoSaveSettingsFormContent'
import { GLOBAL_WINDOW_MESSAGE_ENUM, sendGlobalWindowMessage } from '../../../utils/utils'

export interface AutoSaveSettingsFormValuesType {
  [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: string
  [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: string
}

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingAutoSaveConfigDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { captureAnalytics } = useUserContext()

  const formik = useFormik<AutoSaveSettingsFormValuesType>({
    initialValues: {
      [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: '',
      [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: '',
    },
    onSubmit: async (values) => {
      const settings = {
        [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: Number(
          values[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM],
        ),
        [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: Number(
          values[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD],
        ),
      } as Partial<ExtensionSettingsType>

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings,
      })

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.PUTAWAY_TABS, {
        isMagicMoment: true,
      }).catch(() => {
        console.error('ERROR: Magic moment failed at button click')
      })

      formik.resetForm({ values })
      captureAnalytics('onboarding_tutorial:automatic_save_start', settings)

      onNext()
    },
  })

  const { resetForm, handleSubmit, values } = formik

  useEffect(() => {
    //Load settings from extension
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        const responseValues = {
          [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]:
            settings[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM].toString(),
          [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]:
            settings[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD].toString(),
        }
        resetForm({
          values: responseValues,
        })
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
      })
  }, [resetForm])

  useEffect(() => {
    const settings = {
      [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: Number(
        values[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM],
      ),
      [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: Number(
        values[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD],
      ),
    } as Partial<ExtensionSettingsType>

    sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.CHANGE_AUTOSAVE_SETTINGS, settings)
  }, [values])

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={'How do you want Automatic Save to work?'}
      bodyContent={
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <OnboardingAutoSaveSettingsFormContent />
          </form>
        </FormikProvider>
      }
      descriptionAfterBody={`You can change these options anytime in My Account > Settings.`}
      buttonText={`Activate Automatic Save`}
      onButtonClick={handleSubmit}
    />
  )
}
export default OnboardingAutoSaveConfigDialog
