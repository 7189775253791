import React, { FC, useEffect, useRef } from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'

interface RadioInputOptionProps {
  placeholder: string
  name: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isSelected: boolean
}

const RadioInputOption: FC<RadioInputOptionProps> = (props) => {
  const { name, placeholder, value, onChange, isSelected } = props
  const textInputRef = useRef<HTMLInputElement | null>(null)
  const checkboxInputRef = useRef<HTMLInputElement | null>(null)
  const [inputField, inputMeta] = useField({ name, type: 'text' })
  const isInputFilled = !!inputMeta.value

  useEffect(() => {
    if (isSelected) {
      textInputRef.current?.focus()
    }
  }, [isSelected])

  useEffect(() => {
    if (isInputFilled && !isSelected) {
      checkboxInputRef.current?.click()
    }
  }, [isSelected, isInputFilled])

  return (
    <Radio ref={checkboxInputRef} key={value} value={value} onChange={onChange} colorScheme="gray">
      <Input
        ref={textInputRef}
        fontSize={'12px'}
        fontWeight={500}
        variant="flushed"
        placeholder={placeholder}
        height={'24px'}
        color={isSelected ? '#000' : '#585858'}
        lineHeight="22px"
        _focusVisible={{ borderColor: 'black' }}
        {...inputField}
      />
    </Radio>
  )
}

interface Props {
  name: string
  label: string
  options: {
    value: string
    label: string
  }[]
  shouldIncludeInputOption?: boolean
}

const RadioGroupFormBlock = (props: Props) => {
  const { name, label, options, shouldIncludeInputOption = false } = props
  const [field, meta] = useField(name)
  const { onChange } = field
  const { error, touched, value, initialValue } = meta

  return (
    <FormControl id={name} isInvalid={!!error && !!touched}>
      <FormLabel
        htmlFor={name}
        color={'#585858'}
        fontSize={'14px'}
        fontWeight={'500'}
        lineHeight={'22px'}
      >
        {label}
      </FormLabel>
      <RadioGroup {...field} id={name} ml="20px" padding={0} defaultValue={initialValue}>
        <Stack gap="4px">
          {options.map((option) => (
            <Radio key={option.value} value={option.value} onChange={onChange} colorScheme="gray">
              <span
                style={{
                  color: option.value === value ? '#000' : '#585858',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '22px',
                }}
              >
                {option.label}
              </span>
            </Radio>
          ))}
          {shouldIncludeInputOption && (
            <RadioInputOption
              placeholder="Other"
              name={`${name}_other`}
              value="other"
              onChange={onChange}
              isSelected={value === 'other'}
            />
          )}
        </Stack>
      </RadioGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default RadioGroupFormBlock
