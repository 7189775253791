import { Tooltip, IconButton } from '@chakra-ui/react'
import React, { FC, useRef, useState } from 'react'
import { MdClose, MdCheck } from 'react-icons/md'
import { styled } from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const EditableTitle = styled.input`
  color: #585858;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 18px;
  flex-grow: 1;

  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  cursor: text;
  min-width: 0;

  &::selection {
    background: #cce3f9;
    color: black;
  }
`

interface Props {
  title: string
  handleSaveRename: (newTitle: string) => void
  handleCancelRename: (newTitle: string) => void
}

const EditableProjectTitle: FC<Props> = (props) => {
  const { title, handleCancelRename, handleSaveRename } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)

  return (
    <Container>
      <EditableTitle
        type="text"
        ref={inputRef}
        maxLength={50}
        placeholder={'Project Title'}
        defaultValue={title}
        onBlur={(e) => handleSaveRename(e.currentTarget.value)}
        onChange={(e) => setIsSaveDisabled(e.currentTarget.value.trim() === '')}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            handleSaveRename(e.currentTarget.value)
          } else if (e.key === 'Escape') {
            handleCancelRename(e.currentTarget.value)
          }
        }}
        onFocus={(e) => e.currentTarget.select()}
        autoFocus
      />
      <div className="flex-center" style={{ marginLeft: '4px', height: '100%', cursor: 'pointer' }}>
        <Tooltip placement="top" label="Cancel">
          <IconButton
            variant="outline"
            border="none"
            aria-label="Cancel Rename"
            as="a"
            icon={<MdClose />}
            onClick={() => handleCancelRename(inputRef.current?.value ?? title)}
            height="24px"
            width="24px"
            minWidth="24px"
            _hover={{
              color: '#585858',
              bg: '#EBEBEB',
            }}
          />
        </Tooltip>
        <Tooltip placement="top" label="Save">
          <IconButton
            variant="outline"
            border="none"
            aria-label="Save"
            as="a"
            icon={<MdCheck />}
            onClick={() => handleSaveRename(inputRef.current?.value ?? title)}
            height="24px"
            width="24px"
            minWidth="24px"
            bg="#000000"
            color="#ffffff"
            isDisabled={isSaveDisabled}
            _hover={{
              bg: '#585858',
              color: 'white',
            }}
          />
        </Tooltip>
      </div>
    </Container>
  )
}

export default EditableProjectTitle
