import React, { FC, useEffect } from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { useUserContext } from '../../contexts/UserContext'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../models/browserStorage.types'
import { useLocalStorageBoolean } from '../../utils/genericHooks'

const SURVEY_URL = `https://forms.gle/ar4B5eJ86Pnq6kHGA`

interface Props {
  children: React.ReactNode
}

const NotificationBannerWrapper: FC<Props> = (props) => {
  const { children } = props
  const { captureAnalytics, userInfo } = useUserContext()

  const [isSurveyNotificationVisible, setIsSurveyNotificationVisible] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.IsSurveyNotificationVisible,
    null,
  )

  const onClickOpenSurvey = () => {
    captureAnalytics('notification_banner:open_survey_button_click')
    window.open(SURVEY_URL, '_blank')
    setIsSurveyNotificationVisible(false)
  }

  useEffect(() => {
    if (userInfo && userInfo.date_joined && isSurveyNotificationVisible === null) {
      const dateJoined = new Date(userInfo.date_joined)
      const currentDate = new Date()
      const timeDifference = Math.abs(currentDate.getTime() - dateJoined.getTime())
      const differenceInDays = Math.floor(timeDifference / (1000 * 3600 * 24))

      if (differenceInDays >= 7) {
        captureAnalytics('notification_banner:survey_notification_show')
        setIsSurveyNotificationVisible(true)
      }
    }
  }, [captureAnalytics, userInfo, isSurveyNotificationVisible, setIsSurveyNotificationVisible])

  if (!isSurveyNotificationVisible) {
    return children
  }

  return (
    <Flex h="100%" w="100%" flexDirection="column">
      <Flex
        className="is-ellipsis-1"
        w="100%"
        h="40px"
        alignItems="center"
        justifyContent="center"
        color="white"
        bg="#0071E3"
        p="8px"
      >
        <Text fontSize="14px" fontWeight={500} lineHeight="22px" whiteSpace="pre-wrap">
          {`Got 5 minutes? Help us better understand your needs.`}
        </Text>
        <Button
          h="24px"
          w="95px"
          ml="24px"
          borderRadius="100px"
          backgroundColor="#FFF"
          color="#0071E3"
          fontWeight={500}
          fontSize="12px"
          lineHeight="16px"
          onClick={onClickOpenSurvey}
        >
          Start Survey
        </Button>
      </Flex>
      <Box h="calc(100% - 40px)" w="100%">
        {children}
      </Box>
    </Flex>
  )
}

export default NotificationBannerWrapper
