import React, { FC } from 'react'
import { useField } from 'formik'
import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'

interface Props {
  name: string
  label: string
  placeholder: string
}

const InputFormBlock: FC<Props> = (props) => {
  const { name, label, placeholder } = props
  const [, meta] = useField({ name })
  const { error, touched } = meta
  const [inputField] = useField({ name, type: 'text' })

  return (
    <FormControl id={name} isInvalid={!!error && !!touched}>
      <FormLabel
        id="checkbox-group"
        htmlFor={name}
        color={'#585858'}
        fontSize={'14px'}
        fontWeight={'500'}
        lineHeight={'22px'}
      >
        {label}
      </FormLabel>
      <div role="group" aria-labelledby="checkbox-group">
        <Stack ml="20px" gap={'2px'}>
          <Input
            fontSize={'12px'}
            fontWeight={500}
            variant="flushed"
            placeholder={placeholder}
            height={'24px'}
            _focusVisible={{ borderColor: 'black' }}
            {...inputField}
          />
        </Stack>
      </div>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default InputFormBlock
