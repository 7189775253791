import React, { FC } from 'react'
import { Flex, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import { IoMdArrowBack } from 'react-icons/io'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { css } from '@emotion/css'
import SettingsMainContent from './SettingsMainContent'

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 16px 0px;
  margin-left: 6px;
  gap: 12px;
  cursor: pointer;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }
`

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 24px;
`

const backIconButtonClassName = css`
  & > svg {
    width: 20px;
    height: 20px;
  }
`

const SettingsDashboard: FC = () => {
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const handleClickMenuBack = () => {
    captureAnalytics('settings_dashboard:back_click')
    navigate('/')
  }

  return (
    <>
      <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
        <SearchResultsReplacementWrapper>
          <TableHeaderContainer>
            <IconButton
              className={backIconButtonClassName}
              variant="outline"
              border="none"
              aria-label="Go to home page"
              icon={<IoMdArrowBack />}
              onClick={handleClickMenuBack}
              height="24px"
              width="24px"
              minWidth="24px"
              flexShrink={0}
              _hover={{
                bg: '#EBEBEB',
              }}
            />
            <h2>Settings</h2>
          </TableHeaderContainer>
          <BodyContainer className="scrollbars">
            <SettingsMainContent />
          </BodyContainer>
        </SearchResultsReplacementWrapper>
      </Flex>
    </>
  )
}

export default SettingsDashboard
