import React, { FC, useRef, useEffect, useState } from 'react'
import {
  Flex,
  Box,
  Grid,
  GridItem,
  Icon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { css, cx } from '@emotion/css'
import ProjectsSidebar from './projectsSidebar/ProjectsSidebar'
import SubNavButtons from './SubNavButtons'
import PaymentFlowModal from './payment/PaymentFlowModal'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import CustomDragLayer from './CustomDragLayer'
import SearchBar from './search/SearchBar'
import PinningTutorialOverlay from './onboarding/PinningTutorialOverlay'
import ProjectArchiveDashboard from './projectArchive/ProjectArchiveDashboard'
import { useReduxSelector } from '../../redux/baseStore'
import PutasideTabList from './PutasideTabList/PutasideTabList'
import {
  ONBOARDING_TUTORIAL_STEPS,
  VALID_ONBOARDING_TUTORIAL_STEPS,
} from './onboarding/onboarding.types'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../models/browserStorage.types'
import MagicMomentTablist from './onboarding/MagicMomentTablist'
import PinningTablist from './onboarding/PinningTablist'
import OpenTablist from './onboarding/OpenTablist'
import ProjectDetailDashboard from './projectDetail/ProjectDetailDashboard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HiSparkles } from 'react-icons/hi2'
import { RiHistoryLine } from 'react-icons/ri'
import { useUserContext } from '../../contexts/UserContext'
import { useLocalStorage } from '../../utils/genericHooks'
import FeedHistoryDashboard from './FeedHistoryDashboard'
import OnboardingFlowController from './onboarding/OnboardingFlowController'
import SmartSessionDashboard from './SmartSessionDashboard'

export type CONTENT_TYPE = 'tablist' | 'archive' | 'project'

const disableScrollClassName = css`
  overflow: hidden;
  max-width: 100vw;
`

const disablePointerEventsClassName = css`
  * {
    pointer-events: none;
  }
`
const enablePointerEventsClassName = css`
  * {
    pointer-events: auto;
  }
`

interface Props {
  contentType: CONTENT_TYPE
}

const MainDashboard: FC<Props> = (props) => {
  const { contentType } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams<{ projectId: string }>()
  const authHeader = useReduxSelector((state) => state.user.authHeader)
  const isAuthLoading = useReduxSelector((state) => state.user.isAuthLoading)

  const tablistScrollRef = useRef<HTMLDivElement | null>(null)
  const { userInfo, captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const isPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]

  const [currentStep, setCurrentStep] = useLocalStorage<ONBOARDING_TUTORIAL_STEPS>(
    WEBAPP_LOCAL_STORAGE_KEYS.CurrentOnboardingTutorialStepV2,
    null,
  )
  const isOnboardingTutorialEnabled =
    currentStep !== null &&
    currentStep !== ONBOARDING_TUTORIAL_STEPS.DONE &&
    VALID_ONBOARDING_TUTORIAL_STEPS[currentStep]

  useEffect(() => {
    if (isOnboardingTutorialEnabled && location.pathname !== '/') {
      navigate('/')
    }
  }, [isOnboardingTutorialEnabled, location.pathname, navigate])

  const smartSessionsContainerRef = useRef<HTMLDivElement>(null)
  const isSmartSessionEnabled =
    (!isOnboardingTutorialEnabled ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.Conclusion) &&
    (userInfo?.is_premium ||
      featureFlags[FEATURE_FLAG_KEYS_ENUM.IsSmartSessionsEnabled] ||
      featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldHaveFreeSmartSessions])
  const [tabIndex, setTabIndex] = useState(0)
  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    const tabName = index === 0 ? 'Recent Sessions' : 'History'
    captureAnalytics('morning_page:tab_switch_click', {
      tabName,
    })
  }

  const shouldBlurTablist =
    isOnboardingTutorialEnabled &&
    (currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.TabGroups)

  const shouldBlurFullScreen =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.Preface

  const shouldDisableScroll = shouldBlurFullScreen

  const isProjectsSidebarBlurred =
    isOnboardingTutorialEnabled && currentStep !== ONBOARDING_TUTORIAL_STEPS.TabGroups
  const projectSidebarWidth = isProjectsSidebarBlurred ? '240px' : '320px'

  const isSmartSessionOnboardingActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects

  const isTablistEnabled =
    !isOnboardingTutorialEnabled || currentStep !== ONBOARDING_TUTORIAL_STEPS.Preface

  const isMagicMomentTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig

  const isPinningTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.PinTabs

  const isOpenTablistEnabled =
    isOnboardingTutorialEnabled &&
    (currentStep === ONBOARDING_TUTORIAL_STEPS.Stats ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.Intro ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.Intro2 ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.TabGroups)

  const isPutasideTablistEnabled =
    !isMagicMomentTablistEnabled && !isPinningTablistEnabled && !isOpenTablistEnabled

  const shouldDisableTablistScroll =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.Survey

  useEffect(() => {
    //This boolean exists so this useEffect runs every time the tablist changes
    const isAnyTablistEnabled =
      isTablistEnabled ||
      isMagicMomentTablistEnabled ||
      isPinningTablistEnabled ||
      isOpenTablistEnabled ||
      isPutasideTablistEnabled
    if (tablistScrollRef.current && isAnyTablistEnabled) {
      tablistScrollRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [
    isMagicMomentTablistEnabled,
    isOpenTablistEnabled,
    isPinningTablistEnabled,
    isPutasideTablistEnabled,
    isTablistEnabled,
  ])

  if (isAuthLoading || !authHeader) {
    return null
  }

  return (
    <Flex
      className={shouldDisableScroll ? disableScrollClassName : ''}
      direction="row"
      h="100%"
      w="100%"
    >
      <PinningTutorialOverlay />
      <CustomDragLayer />
      {isPaywallEnabled && <PaymentFlowModal />}
      <OnboardingFlowController
        smartSessionsContainerRef={smartSessionsContainerRef}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
      />
      <Box
        className={isOnboardingTutorialEnabled ? disablePointerEventsClassName : ''}
        minWidth={projectSidebarWidth}
        w={projectSidebarWidth}
        maxW={projectSidebarWidth}
        h="100%"
        overflow="hidden"
      >
        <ProjectsSidebar
          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
          isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
          isProjectsSidebarBlurred={isProjectsSidebarBlurred}
          detailProjectId={projectId}
        />
      </Box>
      <Grid
        className={isOnboardingTutorialEnabled ? disablePointerEventsClassName : ''}
        templateColumns="repeat(12, 1fr)"
        templateRows="68px auto"
        flex="1 1 auto"
        p="24px 24px 0 24px"
      >
        <GridItem
          colSpan={8}
          gridRow={1}
          minWidth="552px"
          maxWidth="1000px"
          opacity={isOnboardingTutorialEnabled ? 0.4 : 1}
        >
          <SearchBar />
        </GridItem>

        <GridItem
          colSpan={4}
          gridColumnStart={9}
          gridRow={1}
          minWidth="74px"
          opacity={isOnboardingTutorialEnabled ? 0.1 : 1}
        >
          <SubNavButtons />
        </GridItem>

        {contentType === 'tablist' && (
          <GridItem
            ref={tablistScrollRef}
            className={cx({
              scrollbars: true,
              [enablePointerEventsClassName]: !isPutasideTablistEnabled,
            })}
            pointerEvents="auto"
            colSpan={12}
            gridRow={2}
            overflow={shouldDisableTablistScroll ? 'hidden' : 'auto'}
            borderRadius="16px"
            backgroundColor={isSmartSessionEnabled ? '#F6F6F6' : 'white'}
          >
            <Grid
              templateColumns="repeat(12, 1fr)"
              width="100%"
              flex="1 1 auto"
              minHeight="100%"
              maxWidth={'1600px'}
            >
              <GridItem
                colSpan={8}
                minWidth="552px"
                maxWidth={isOnboardingTutorialEnabled ? '650px' : '1000px'}
                borderRadius="16px"
                backgroundColor={isSmartSessionEnabled || !isTablistEnabled ? undefined : '#f6f6f6'}
                opacity={shouldBlurTablist ? 0.3 : 1}
                overflow="hidden"
              >
                {isTablistEnabled && (
                  <>
                    {isMagicMomentTablistEnabled && <MagicMomentTablist />}
                    {isPinningTablistEnabled && <PinningTablist />}
                    {isOpenTablistEnabled && <OpenTablist />}
                    {isPutasideTablistEnabled && (
                      <PutasideTabList isOnboardingTutorialEnabled={isOnboardingTutorialEnabled} />
                    )}
                  </>
                )}
              </GridItem>
              <GridItem
                ref={smartSessionsContainerRef}
                colSpan={4}
                minWidth={isSmartSessionEnabled ? '300px' : undefined}
                overflow="hidden"
              >
                {isSmartSessionEnabled && (
                  <Tabs index={tabIndex} onChange={handleTabsChange} isFitted variant="unstyled">
                    <TabList
                      mt="24px"
                      ml="8px"
                      mr="16px"
                      border={'1px'}
                      borderColor={'#A7A7A7'}
                      borderRadius={'8px'}
                    >
                      <Tab
                        fontSize={'14px'}
                        fontWeight={600}
                        borderRadius={'8px 0 0 8px'}
                        borderColor={'#D5D5D5'}
                        color={'#A7A7A7'}
                        _selected={{ color: '#585858', bg: '#CCE3F9' }}
                        _hover={tabIndex !== 0 ? { bg: '#EBEBEB', color: '#585858' } : {}}
                      >
                        <Icon as={HiSparkles} mr={'4px'} />
                        Draft Projects
                      </Tab>
                      <Tab
                        fontSize={'14px'}
                        fontWeight={600}
                        borderRadius={'0 8px 8px 0'}
                        borderColor={'#D5D5D5'}
                        color={'#A7A7A7'}
                        _selected={{ color: '#585858', bg: '#CCE3F9' }}
                        _hover={tabIndex !== 1 ? { bg: '#EBEBEB', color: '#585858' } : {}}
                      >
                        <Icon as={RiHistoryLine} mr={'4px'} />
                        History
                      </Tab>
                    </TabList>
                    <TabIndicator display={'none'} />
                    <TabPanels mt={'16px'}>
                      <TabPanel p={'0px'}>
                        <SmartSessionDashboard
                          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
                        />
                      </TabPanel>
                      <TabPanel p={'0px'}>
                        <FeedHistoryDashboard />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
              </GridItem>
            </Grid>
          </GridItem>
        )}

        {(contentType === 'archive' || contentType === 'project') && (
          <GridItem
            colSpan={8}
            rowStart={2}
            rowSpan={2}
            height="100%"
            minWidth="552px"
            maxWidth="1000px"
            borderRadius="16px"
            backgroundColor="#f6f6f6"
            className="scrollbars"
            overflow="auto"
          >
            {contentType === 'archive' && <ProjectArchiveDashboard authHeader={authHeader} />}
            {contentType === 'project' && !!projectId && (
              <ProjectDetailDashboard projectId={projectId} />
            )}
          </GridItem>
        )}
      </Grid>
    </Flex>
  )
}

export default MainDashboard
