import React, { FC } from 'react'
import { Flex, Box, Img, Link } from '@chakra-ui/react'
import ProjectsList from './ProjectsList'
import { useUserContext } from '../../../contexts/UserContext'
import { css, styled } from 'styled-components'
import { HiOutlineArchive } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../contexts/AuthContext'
import { useBrandingContext } from '../../../contexts/BrandingContext'

const NavLinkContainer = styled.div<{
  $isSelected: boolean
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box;
  gap: 6px;
  transition: padding-left 0.2s;
  border: 1.5px solid transparent;

  h2 {
    color: #585858;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    cursor: pointer;
  }

  svg {
    color: #585858;
    width: 24px;
    height: 24px;
  }

  &:hover,
  &.force-hover {
    padding-left: 14px;

    ${({ $isSelected }) =>
      !$isSelected
        ? css`
            background-color: #ebebeb;
          `
        : ''};

    h2,
    svg {
      color: #000;
    }
  }

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          padding-left: 14px;
          border: 1.5px solid #585858;

          h2,
          svg {
            color: #000;
          }
        `
      : ''}
`

interface Props {
  isProjectsSidebarBlurred: boolean
  isOnboardingTutorialEnabled: boolean
  isSmartSessionOnboardingActive: boolean
  detailProjectId?: string
}

const ProjectsSidebar: FC<Props> = (props) => {
  const {
    isProjectsSidebarBlurred,
    isOnboardingTutorialEnabled,
    isSmartSessionOnboardingActive,
    detailProjectId,
  } = props
  const { authHeader } = useAuthContext()
  const { captureAnalytics } = useUserContext()
  const { APP_LOGO_WORDMARK_URL, APP_NAME } = useBrandingContext()
  const navigate = useNavigate()

  const isArchiveSelected = window.location.pathname === '/archive'

  const onClickArchive = () => {
    captureAnalytics('sidebar:archive_button_clicked')
    navigate('/archive')
  }

  const handleClickLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('sidebar:logo_clicked')
    navigate('/')
  }

  if (!authHeader) {
    return null
  }

  return (
    <Flex direction="column" justifyContent={'space-between'} h="100%" w="100%">
      <Box display={'flex'} alignItems={'center'} m="36px 24px 32px 24px">
        <Link href="/" onClick={handleClickLogo}>
          <Img src={APP_LOGO_WORDMARK_URL} alt={`${APP_NAME} logo`} height={'22px'} />
        </Link>
      </Box>
      <Box flex="1" overflow="hidden" width="100%" p="0 20px 0px 20px">
        <ProjectsList
          authHeader={authHeader}
          isProjectsSidebarBlurred={isProjectsSidebarBlurred}
          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
          detailProjectId={detailProjectId}
          isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
        />
      </Box>
      <Box p="24px" opacity={isOnboardingTutorialEnabled ? 0.4 : 1}>
        <NavLinkContainer $isSelected={isArchiveSelected} onClick={onClickArchive}>
          <HiOutlineArchive fontSize="1.5em" />
          <h2>Archive</h2>
        </NavLinkContainer>
      </Box>
    </Flex>
  )
}

export default ProjectsSidebar
