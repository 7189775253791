import React, { useCallback, useContext, useEffect } from 'react'

type AuthContextType = {
  authHeader: string | undefined
  isAuthenticated: boolean
  signIn: (token: string) => Promise<boolean>
  signOut: () => Promise<void>
}

export const AuthContext = React.createContext<AuthContextType>({
  authHeader: undefined,
  isAuthenticated: false,
  signIn: () => Promise.resolve(false),
  signOut: () => Promise.resolve(),
})

export const useAuthContext = (): AuthContextType => {
  return useContext(AuthContext)
}

abstract class AuthContextBackend {
  abstract setAuthHeader(header: string): Promise<void>
  abstract clearAuthHeader(): Promise<void>
  abstract getAuthHeader(): Promise<string | undefined>
}

export class ChromeStorageAuthContextBackend extends AuthContextBackend {
  constructor(private readonly key: string) {
    super()
  }

  async setAuthHeader(header: string): Promise<void> {
    const token = header.replace('Bearer ', '')
    return chrome.storage.local.set({ [this.key]: token })
  }

  async clearAuthHeader(): Promise<void> {
    await chrome.storage.local.remove(this.key)
  }

  async getAuthHeader(): Promise<string | undefined> {
    const token = (await chrome.storage.local.get([this.key]))[this.key] as string | undefined
    return token ? `Bearer ${token}` : undefined
  }
}

export class LocalStorageAuthContextBackend extends AuthContextBackend {
  constructor(private readonly key: string) {
    super()
  }

  setAuthHeader(header: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(this.key, header)
      resolve()
    })
  }

  clearAuthHeader(): Promise<void> {
    return new Promise((resolve) => {
      localStorage.removeItem(this.key)
      resolve()
    })
  }

  getAuthHeader(): Promise<string | undefined> {
    return new Promise((resolve) => {
      const item = localStorage.getItem(this.key)
      if (item === null) {
        // Try if there are keys from react-auth-kit, which is saved with a key "undefined"
        const reactAuthKitItem = localStorage.getItem('undefined')
        if (reactAuthKitItem) {
          const authHeader = `Bearer ${reactAuthKitItem}`
          this.setAuthHeader(authHeader)
          resolve(authHeader)
        }
      } else {
        resolve(item ? item : undefined)
      }
    })
  }
}

export const AuthContextProvider: React.FC<{
  backend: AuthContextBackend
  children: React.ReactNode
}> = ({ backend, children }) => {
  const [authHeader, setAuthHeader] = React.useState<string | undefined>(undefined)

  useEffect(() => {
    void backend
      .getAuthHeader()
      .then((header) => {
        setAuthHeader(header)
      })
      .catch((e) => {
        console.error('Error initializing auth header', e)
      })
  }, [backend])

  const signIn = useCallback(
    async (token: string): Promise<boolean> => {
      const authHeader = `Bearer ${token}`
      await backend.setAuthHeader(authHeader)
      setAuthHeader(authHeader)
      return true
    },
    [backend],
  )

  const signOut = useCallback(async () => {
    setAuthHeader(undefined)
    await backend.clearAuthHeader()
  }, [backend])

  return (
    <AuthContext.Provider value={{ authHeader, isAuthenticated: !!authHeader, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
