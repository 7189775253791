import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import searchReducer from '../../redux/searchSlice'
import userReducer from '../../redux/userSlice'
import dndReducer from '../../redux/dndSlice'
import projectsReducer from '../../redux/projectsSlice'
import tablistReducer from '../../redux/tablistSlice'
import { skeemaApi } from '../../redux/services/skeema'
import { extensionMessageApi } from './extension'

const webappStore = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    dnd: dndReducer,
    projects: projectsReducer,
    tablist: tablistReducer,
    [skeemaApi.reducerPath]: skeemaApi.reducer,
    [extensionMessageApi.reducerPath]: extensionMessageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(skeemaApi.middleware).concat(extensionMessageApi.middleware),
})

setupListeners(webappStore.dispatch)

export default webappStore
