import React, { FC } from 'react'
import { ONBOARDING_TUTORIAL_STEPS } from './onboarding.types'
import OnboardingTablistDialog from './OnboardingTablistDialog'
import OnboardingPinTabsDialog from './OnboardingPinTabsDialog'
import OnboardingAutoSaveConfigDialog from './OnboardingAutoSaveConfigDialog'
import OnboardingIntroDialog from './OnboardingIntroDialog'
import OnboardingPrefaceDialog from './OnboardingPrefaceDialog'
import OnboardingStatsDialog from './OnboardingStatsDialog'
import OnboardingIntro2Dialog from './OnboardingIntro2Dialog'
import OnboardingTabGroupsDialog from './OnboardingTabGroupsDialog'
import OnboardingDraftProjectsDialog from './OnboardingDraftProjectsDialog'
import OnboardingConclusionDialog from './OnboardingConclusionDialog'
import OnboardingSurveyDialog from './OnboardingSurveyDialog'

interface Props {
  currentStep: ONBOARDING_TUTORIAL_STEPS
  onNext: () => void
  currentStepIdx: number
  numSteps: number
  didDoTabGroups: boolean
}

const OnboardingDialogs: FC<Props> = (props) => {
  const { onNext, currentStep, currentStepIdx, numSteps, didDoTabGroups } = props

  if (currentStep === ONBOARDING_TUTORIAL_STEPS.Preface) {
    return (
      <OnboardingPrefaceDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Stats) {
    return (
      <OnboardingStatsDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Intro) {
    return (
      <OnboardingIntroDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Intro2) {
    return (
      <OnboardingIntro2Dialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.TabGroups) {
    return (
      <OnboardingTabGroupsDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.TabList) {
    return (
      <OnboardingTablistDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.PinTabs) {
    return (
      <OnboardingPinTabsDialog
        didDoTabGroups={didDoTabGroups}
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig) {
    return (
      <OnboardingAutoSaveConfigDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects) {
    return (
      <OnboardingDraftProjectsDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Survey) {
    return (
      <OnboardingSurveyDialog phaseNum={currentStepIdx + 1} numPhases={numSteps} onNext={onNext} />
    )
  } else if (currentStep === ONBOARDING_TUTORIAL_STEPS.Conclusion) {
    return (
      <OnboardingConclusionDialog
        phaseNum={currentStepIdx + 1}
        numPhases={numSteps}
        onNext={onNext}
      />
    )
  }
}

export default OnboardingDialogs
