import React, { FC, useCallback } from 'react'
import { useUserContext } from '../../../contexts/UserContext'
import { ProjectPageType } from '../../../models/saved_sessions.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { shouldOpenInNewTab } from '../../../utils/utils'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'
import PutasideTabView from '../PutasideTabList/PutasideTabView'

interface PropTypes {
  projectId: string
  page: ProjectPageType
  isExtensionInstalled: boolean
}

const ProjectDetailSharedPageController: FC<PropTypes> = (props) => {
  const { projectId, page, isExtensionInstalled } = props
  const { captureAnalytics } = useUserContext()

  const handleClickTitle = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation()
      if (isExtensionInstalled) {
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
          urls: [page.url],
          shouldActivate: !shouldOpenInNewTab(event),
        })
      } else {
        window.open(page.url, '_blank')
      }
      captureAnalytics('project_detail_dashboard:page_title_click', { projectId, ...page })
    },
    [isExtensionInstalled, captureAnalytics, projectId, page],
  )

  return (
    <PutasideTabView
      title={page.title}
      favIconUrl={page.favicon_url}
      handleClickFavIcon={handleClickTitle}
      handleClickTitle={handleClickTitle}
      handleClickBody={handleClickTitle}
      handleClickDragIcon={handleClickTitle}
      isDraggingThis={false}
      isDraggingDisabled={true}
      entityType={TABLIST_PAGE_ENUM.PROJECT_PAGE}
      lastAccessDateTime={page.last_access_datetime ?? ''}
      isChecked={false}
      isCheckboxShown={false}
      isSelectionDisabled={false}
      isHoverDisabled={false}
      isTitleClickDisabled={false}
      showTimeString={false}
      showActionIcons={false}
      isFocused={false}
      spacingVariant={'default'}
      titleToolTip={page.url}
    />
  )
}

export default ProjectDetailSharedPageController
