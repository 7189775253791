import React, { FC } from 'react'
import GenericModal from '../../../widgets/GenericModal'
import { useBrandingContext } from '../../../contexts/BrandingContext'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
}

const ProjectLimitModal: FC<Props> = (props) => {
  const { isOpen, onCancel, onSubmit } = props
  const { APP_NAME } = useBrandingContext()

  return (
    <GenericModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      headingText={`Get more organized with ${APP_NAME} Pro`}
      bodyText={`${APP_NAME} Basic provides up to 10 Projects. Upgrade to Pro for up to 100 Projects.`}
      cancelButtonText="Dismiss"
      submitButtonText="Upgrade"
    />
  )
}

export default ProjectLimitModal
