export enum CHROME_STORAGE_LOCAL_KEYS {
  AuthToken = 'AuthToken',
  Notifications = 'Notifications',
  IsSidePanelOnLeft = 'IsSidePanelOnLeft',
  Options = 'Options',
  FeatureFlagValues = 'FeatureFlagValues',
  UserInfo = 'UserInfo',
  TabRecords = 'TabRecords',
  IsTabRecordsInitialized = 'IsTabRecordsInitialized',
  TabRecordsLastUpdateTime = 'TabRecordsLastUpdateTime',
  WindowRecord = 'WindowRecord',
  IsAutoPutawayDisabled = 'IsAutoPutawayDisabled',
  DiagnosticRecords = 'DiagnosticRecords',
  PinnedTabIds = 'PinnedTabIds',
  DiagnosticLogs = 'DiagnosticLogs',
  WindowSessions = 'WindowSessions',
  IsOnboardingCompleted = 'IsOnboardingCompleted',
}

export const CHROME_STORAGE_LOCAL_DYNAMIC_KEYS = {
  TabRecord: (id: string) => `Tab_${id}`,
  IsSidePanelOpen: (windowId: string) => `IsSidePanelOpen_${windowId}`,
}

export const CHROME_STORAGE_LOCAL_DEFAULT_KEYS = {
  [CHROME_STORAGE_LOCAL_KEYS.AuthToken]: undefined,
  [CHROME_STORAGE_LOCAL_KEYS.Notifications]: undefined,
  [CHROME_STORAGE_LOCAL_KEYS.IsAutoPutawayDisabled]: false,
}

export enum CHROME_STORAGE_SYNC_KEYS {
  IsFloatingRibbonDisabled = 'IsFloatingRibbonDisabled',
  FloatingRibbonBlacklistedUrls = 'FloatingRibbonBlacklistedUrls',
  ExtensionSettings = 'ExtensionSettings',
}

export const CHROME_STORAGE_SYNC_DEFAULT_KEYS = {
  [CHROME_STORAGE_SYNC_KEYS.IsFloatingRibbonDisabled]: false,
  [CHROME_STORAGE_SYNC_KEYS.FloatingRibbonBlacklistedUrls]: [
    'https://www.disneyplus.com/',
    'https://www.netflix.com/',
    'https://www.youtube.com/',
    'https://www.hulu.com/',
  ],
}

export enum EXTENSION_WINDOW_LOCAL_STORAGE_KEYS {
  IsPinningTutorialVisible = 'IsPinningTutorialVisible',
  CurrentOnboardingTutorialStep = 'CurrentOnboardingTutorialStep',
  ShouldHidePopupDialog = 'ShouldHidePopupDialog',
}

export enum EXTENSION_SESSION_STORAGE_KEYS {
  SkeemaUserSession = 'SkeemaUserSession',
}

export const CHROME_STORAGE_SESSION_DYNAMIC_KEYS = {
  IsSidePanelOpen: (windowId: string) => `IsSidePanelOpen_${windowId}`,
}
