import React, { FC } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { useResetUserDataMutation } from '../../../redux/services/skeema/users.endpoints'
import { useUserContext } from '../../../contexts/UserContext'

const AdminSettingsContent: FC = () => {
  const { userInfo } = useUserContext()
  const [resetUserData] = useResetUserDataMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef(null)

  const handleConfirmResetUserData = () => {
    if (!userInfo || !userInfo.pk) {
      alert('User info not found')
      return
    }
    resetUserData({ id: userInfo.pk })
    onClose()
  }

  return (
    <Box my={2}>
      <Button colorScheme="red" onClick={onOpen}>
        Reset Data
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              {"Are you sure? You can't undo this action afterwards."}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmResetUserData} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}

export default AdminSettingsContent
