import { skeemaApi } from '../skeema'
import {
  SMART_SESSIONS_ENDPOINT_PATH_V2,
  SmartSessionsEndpointV2Type,
} from '../../../models/endpoints.types'
import { TablistPageType } from '../../../models/tablist_pages.types'

export const smartSessionsApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getSmartSessionsWithOpenTabsV2: builder.query<
      SmartSessionsEndpointV2Type['POST']['response'],
      {
        openTabs?: TablistPageType[]
        from_ts?: number
        to_ts?: number
      }
    >({
      query: ({ openTabs, from_ts, to_ts }) => {
        const open_tabs =
          openTabs === undefined
            ? []
            : openTabs
                .map((t) => ({
                  tab_id: t.id,
                  url: t.url,
                  title: t.title,
                  favicon_url: t.favicon_url,
                  window_id: t.window_id,
                  last_access_time_ms: t.last_access_timestamp_ms,
                }))
                .filter((t) => {
                  return !!t.url && !!t.title
                })

        return {
          url: `${SMART_SESSIONS_ENDPOINT_PATH_V2}`,
          method: 'POST',
          body: {
            open_tabs,
            from_ts,
            to_ts,
          },
        }
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        const { openTabs, from_ts, to_ts } = queryArgs
        // Avoid refetches triggerred by updated open tab timestamps
        const openTabsWithoutTimestamps = openTabs?.map((t) => ({
          tab_id: t.id,
          url: t.url,
          title: t.title,
          favicon_url: t.favicon_url,
          window_id: t.window_id,
        }))
        return {
          queryArgs: {
            openTabs: openTabsWithoutTimestamps,
            from_ts,
            to_ts,
          },
          endpointDefinition,
          endpointName,
        }
      },
    }),
  }),
})

export const {
  useGetSmartSessionsWithOpenTabsV2Query,
  useLazyGetSmartSessionsWithOpenTabsV2Query,
} = smartSessionsApi
