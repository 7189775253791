import React, { FC } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
  headingText: string
  bodyText: string
  cancelButtonText: string
  submitButtonText: string
  shouldShowCloseIcon?: boolean
}

const GenericModal: FC<Props> = (props) => {
  const {
    isOpen,
    onCancel,
    onSubmit,
    headingText,
    bodyText,
    cancelButtonText,
    submitButtonText,
    shouldShowCloseIcon = false,
  } = props

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader>{headingText}</ModalHeader>
        {shouldShowCloseIcon && <ModalCloseButton />}
        <ModalBody>
          <Text fontSize="16px" color="black">
            {bodyText}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button borderRadius={'100px'} variant="ghost" mr={3} onClick={onCancel}>
            {cancelButtonText}
          </Button>

          <Button
            size="md"
            variant="solid"
            borderRadius="100px"
            paddingRight="24px"
            paddingLeft="24px"
            backgroundColor={'black'}
            color={'white'}
            _hover={{
              backgroundColor: '#585858',
            }}
            onClick={onSubmit}
            autoFocus
          >
            {submitButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GenericModal
