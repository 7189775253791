import React, { FC, useEffect, useRef } from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { useUserContext } from '../../contexts/UserContext'
import AnnouncementsPage from '../../components/AnnouncementsPage'
import { IoMdArrowBack } from 'react-icons/io'
import { useBrandingContext } from '../../contexts/BrandingContext'
import { useNavigate } from 'react-router-dom'

const NotFoundPage: FC = () => {
  const { APP_NAME, SUPPORT_EMAIL } = useBrandingContext()
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()
  const didLogRef = useRef(false)

  useEffect(() => {
    if (didLogRef.current) {
      return
    }

    captureAnalytics('not_found_page:view', { url: window.location.href })
    didLogRef.current = true
  }, [captureAnalytics])

  const onClickContactSupport = () => {
    captureAnalytics('not_found_page:click_contact_support')
  }

  const onClickGoHome = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    navigate('/')
    captureAnalytics('not_found_page:click_go_home')
  }

  return (
    <AnnouncementsPage
      heading={'404 Not Found'}
      content={[
        'Please check the website URL and try again.',
        `If the problem persists, please email ${SUPPORT_EMAIL}`,
      ]}
      widget={
        <Flex mb="4">
          <Button
            as="a"
            backgroundColor="black"
            borderRadius="20px"
            borderColor="#D5D5D5"
            color="white"
            fontWeight="normal"
            padding="0 30px"
            title={`Return to ${APP_NAME} Home`}
            href={`/`}
            rel="noreferrer"
            onClick={onClickGoHome}
            leftIcon={<IoMdArrowBack />}
          >
            Return to Home
          </Button>
          <Button
            as="a"
            ml="16px"
            backgroundColor="black"
            borderRadius="20px"
            borderColor="#D5D5D5"
            color="white"
            fontWeight="normal"
            padding="0 30px"
            title={`Contact ${APP_NAME} Support`}
            href={`mailto:${SUPPORT_EMAIL}?subject=Errors on ${window.location.href}`}
            target="_blank"
            rel="noreferrer"
            onClick={onClickContactSupport}
          >
            Contact Support
          </Button>
        </Flex>
      }
    />
  )
}

export default NotFoundPage
