import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { MdOutlineCreateNewFolder } from 'react-icons/md'
import { useGetSmartSessionsWithOpenTabsV2Query } from '../../../redux/services/skeema/smart_sessions.endpoints'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingDraftProjectsDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const [isInit, setIsInit] = useState<boolean>(false)
  const { data: openTabPages, isLoading: isOpenTabsLoading } = useGetOpenTabsQuery(undefined)
  const { data: sessionsQueryData, isLoading: isSmartSessionsLoading } =
    useGetSmartSessionsWithOpenTabsV2Query({ openTabs: openTabPages }, { skip: isOpenTabsLoading })
  const numSmartSessions = sessionsQueryData?.sessions.length ?? 0

  useEffect(() => {
    if (isOpenTabsLoading || isSmartSessionsLoading) {
      return
    }
    if (numSmartSessions === 0) {
      onNext()
    }
    setIsInit(true)
  }, [isOpenTabsLoading, isSmartSessionsLoading, numSmartSessions, onNext])

  if (isOpenTabsLoading || isSmartSessionsLoading) {
    return <OnboardingStepTemplate phaseNum={phaseNum} numPhases={numPhases} isCardLoading={true} />
  }

  return (
    <OnboardingStepTemplate
      isCardLoading={!isInit}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        <>
          Lastly,{' '}
          <u
            style={{
              fontSize: 'inherit',
              fontWeight: 500,
              lineHeight: '40px',
              color: '#000',
              letterSpacing: '-1px',
            }}
          >
            Draft Projects
          </u>
          {` are AI-generated groups of tabs that snapshot your recent browsing. Save this Draft Project as a Project?`}
        </>
      }
      description={
        <>
          {`Click `}
          <MdOutlineCreateNewFolder
            style={{
              margin: '0px 0px -6px 0px',
              display: 'inline',
              width: '24px',
              height: '24px',
              padding: '2px',
              color: '#585858',
            }}
          />
          {` to save the Draft Project.`}
        </>
      }
      buttonText={`Next`}
      onButtonClick={onNext}
    />
  )
}
export default OnboardingDraftProjectsDialog
