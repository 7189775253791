import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Collapse,
  Box,
  ToastId,
  UseToastOptions,
  useToast,
  Flex,
  Heading,
  Icon,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import PutasideTabController from './PutasideTabController'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { styled } from 'styled-components'
import FavIconList, { FavIconListItemType } from '../FavIconList'
import { useUserContext } from '../../../contexts/UserContext'
import { useReduxSelector } from '../../../redux/baseStore'
import { selectSearchQueryValue } from '../../../redux/searchSlice'
import { useGetTablistPagesQuery } from '../../../redux/services/skeema/tablist_pages.endpoints'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import InstantSaveToast from '../instantSave/InstantSaveToast'
import { cx, css } from '@emotion/css'
import SmartSessionDashboard from '../SmartSessionDashboard'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { MdOutlineInbox, MdOutlineViewAgenda } from 'react-icons/md'
import SavedForLaterList from './SavedForLaterList'

export interface SelectedTablistPageType {
  [id: string]: TablistPageType
}

export enum TABLIST_AREA_NAME_ENUM {
  MostUsed = 'most_used_tabs',
  Open = 'open_tabs',
  RecentlySaved = 'other_recently_used_tabs',
  SearchResults = 'search_results',
  SmartSessions = 'smart_sessions',
  FeedHistory = 'feed_history',
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-left: 12px;
`

const TableHeaderCell = styled.div<{ $isClickable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
`

const CollapseIcon = styled.span<{ $isCollapsed: boolean }>`
  height: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  width: 16px;
  color: black;

  & > svg {
    display: flex;
    transform: ${({ $isCollapsed }) => `rotate(${$isCollapsed ? -90 : 0}deg)`};
    transition: transform 0.5s;
    transform-origin: 50% 50%;
  }
`

const onboardingPrepAnimationClassName = css`
  transition: opacity 2000ms ease-in-out 500ms;
  opacity: 0;
`

const onboardingAnimationClassName = css`
  opacity: 1;
`

const TOAST_DURATION_MS = 8000
const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

export enum PUTASIDE_TABLIST_VIEW_ENUM {
  SESSIONS = 'SESSIONS',
  SAVED_TABS = 'SAVED_TABS',
}
interface Props {
  defaultView: PUTASIDE_TABLIST_VIEW_ENUM
  isOnboardingTutorialEnabled?: boolean
  isSmartSessionOnboardingActive?: boolean
  isSavedTabsOnboardingStepActive?: boolean
}

const PutasideTabList: FC<Props> = (props) => {
  const {
    defaultView,
    isOnboardingTutorialEnabled = false,
    isSmartSessionOnboardingActive = false,
    isSavedTabsOnboardingStepActive = false,
  } = props
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const navigate = useNavigate()

  const [_activeView, setActiveView] = useState<
    PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS | PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
  >(defaultView)
  const activeView = isSmartSessionOnboardingActive
    ? PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    : isSavedTabsOnboardingStepActive
      ? PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
      : _activeView

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = React.useRef<ToastId | undefined>(undefined)

  const IsPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]

  const instantSaveQuota = useReduxSelector((state) => state.user.instantSaveQuota)
  const queryValue = useReduxSelector(selectSearchQueryValue)

  const isSearchingActive = queryValue.trim().length > 0
  const isResultsModeEnabled = isSearchingActive

  const [isMostUsedPagesCollapsed, setIsMostUsedPagesCollapsed] = useState<boolean>(
    !isOnboardingTutorialEnabled,
  )

  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(isOnboardingTutorialEnabled)
    }, 500)
  }, [isOnboardingTutorialEnabled])

  const { data: tablistPagesQueryResult } = useGetTablistPagesQuery(undefined)
  const mostVisitedPages = tablistPagesQueryResult?.most_visited_pages

  const handleOnClickUpgrade = useCallback(() => {
    captureAnalytics('instant_save_toast:upgrade_click')
    navigate('/#pricing')
  }, [captureAnalytics, navigate])

  const handleClickMostUsedSection = useCallback(() => {
    setIsMostUsedPagesCollapsed((prev) => {
      captureAnalytics('putaside_tab_list:most_used_section_expand', {
        isExpanded: !prev,
      })
      return !prev
    })
  }, [captureAnalytics])

  const handleClickSessionsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS) {
        captureAnalytics('putaside_tab_list:sessions_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
    })
  }, [captureAnalytics])

  const handleClickAllSavedTabsTab = useCallback(() => {
    setActiveView((prev) => {
      if (prev !== PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS) {
        captureAnalytics('putaside_tab_list:all_saved_tabs_tab_click')
      }
      return PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
    })
  }, [captureAnalytics])

  useEffect(() => {
    // Update the toast if the instant save quota changes
    if (IsPaywallEnabled && toastIdRef.current) {
      const toastParams = {
        duration: TOAST_DURATION_MS,
        render: () => (
          <InstantSaveToast
            instantSaveQuota={instantSaveQuota}
            onClickUpgrade={handleOnClickUpgrade}
          />
        ),
      }
      toast.update(toastIdRef.current, toastParams)
    }
  }, [IsPaywallEnabled, handleOnClickUpgrade, instantSaveQuota, toast])

  return (
    <Box
      className={cx({
        [onboardingPrepAnimationClassName]: isOnboardingTutorialEnabled,
        [onboardingAnimationClassName]: shouldAnimate,
      })}
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 16px">
        <SearchResultsReplacementWrapper>
          <Collapse in={!isResultsModeEnabled} animateOpacity>
            {mostVisitedPages && mostVisitedPages.length > 0 && (
              <SectionContainer className="most-used-section">
                <TableHeaderContainer>
                  <TableHeaderCell
                    onClick={
                      isSmartSessionOnboardingActive ? undefined : handleClickMostUsedSection
                    }
                    $isClickable={!isSmartSessionOnboardingActive}
                  >
                    <h2>Most used</h2>
                    <CollapseIcon $isCollapsed={!!isMostUsedPagesCollapsed}>
                      <FiChevronDown />
                    </CollapseIcon>
                  </TableHeaderCell>
                </TableHeaderContainer>

                <span
                  style={{
                    flex: '1 2 auto',
                    display: isMostUsedPagesCollapsed ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    overflow: 'auto',
                    minWidth: '24px',
                    maxWidth: '100%',
                    padding: '0 6px',
                  }}
                >
                  <FavIconList
                    items={mostVisitedPages.map((t) => {
                      return {
                        id: t.id,
                        url: t.url,
                        title: t.title,
                        faviconUrl: t.favicon_url,
                      } as FavIconListItemType
                    })}
                    size="md"
                    showTitleTooltip={true}
                    isClickable={true}
                    areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                  />
                </span>

                <Collapse in={!isMostUsedPagesCollapsed} animateOpacity>
                  <div>
                    {mostVisitedPages?.map((t, idx) => {
                      return (
                        <PutasideTabController
                          key={t.id}
                          id={t.id}
                          page={t}
                          showTimeString={false}
                          queryValue={queryValue}
                          index={idx}
                          numTotalResults={mostVisitedPages.length}
                          areaName={TABLIST_AREA_NAME_ENUM.MostUsed}
                          isDraggingDisabled={isSmartSessionOnboardingActive}
                          isHoverDisabled={isSmartSessionOnboardingActive}
                          isTitleClickDisabled={isSmartSessionOnboardingActive}
                        />
                      )
                    })}
                  </div>
                </Collapse>
              </SectionContainer>
            )}
            <SectionContainer>
              <Box mb="12px">
                <Flex m="0px 0px" alignItems="center" position="relative" zIndex={2}>
                  <Box cursor="pointer" p="10px 10px 0 10px" onClick={handleClickSessionsTab}>
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineViewAgenda} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="14px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        Sessions
                      </Heading>
                    </Flex>
                  </Box>

                  <Box cursor="pointer" p="10px 10px 0 10px" onClick={handleClickAllSavedTabsTab}>
                    <Flex
                      p="0 2px 6px 2px"
                      alignItems="center"
                      borderBottom="2px solid"
                      borderBottomColor={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? '#000'
                          : 'transparent'
                      }
                      color={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS ? '#000' : '#585858'
                      }
                      _hover={
                        activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                          ? undefined
                          : { borderBottomColor: '#585858' }
                      }
                      transition="all 0.2s"
                      gap="6px"
                    >
                      <Icon as={MdOutlineInbox} width="24px" height="24px" />
                      <Heading
                        as="h2"
                        color="inherit"
                        fontSize="14px"
                        fontWeight={600}
                        lineHeight="24px"
                      >
                        All saved tabs
                      </Heading>
                    </Flex>
                  </Box>
                </Flex>

                <Box
                  borderBottom="1px solid #D5D5D5"
                  margin="0px 8px 0px 10px"
                  position="relative"
                  top="-1px"
                  zIndex={1}
                />
              </Box>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS} animateOpacity>
                <SmartSessionDashboard isSSOnboardingActive={isSmartSessionOnboardingActive} />
              </Collapse>

              <Collapse in={activeView === PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS} animateOpacity>
                <SavedForLaterList />
              </Collapse>
            </SectionContainer>
          </Collapse>
        </SearchResultsReplacementWrapper>
      </Box>
    </Box>
  )
}

export default PutasideTabList
