import React, { FC } from 'react'
import { AuthContextProvider, LocalStorageAuthContextBackend } from '../contexts/AuthContext'
import { isMobile } from 'react-device-detect'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ChakraProvider } from '@chakra-ui/react'
import './WebApp.scss'
import { BrowserRouter } from 'react-router-dom'
import {
  ExtensionHealthContextProvider,
  WebAppExtensionHealthContextBackend,
} from '../contexts/ExtensionHealthContext'
import { BrandingContextProvider } from '../contexts/BrandingContext'
import { UserContextProvider } from '../contexts/UserContext'
import { OAUTH_WEB_CLIENT_ID } from '../constants'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
  FeatureFlagContextProvider,
  LocalStorageFeatureFlagContextBackend,
} from '../contexts/FeatureFlagContext'
import MobileAnnouncementsPage from '../components/MobileAnnouncementsPage'
import { Provider as ReduxProvider } from 'react-redux'
import webappStore from './redux/webappStore'
import chakraTheme from '../styles/chakraTheme'
import MainPage from './pages/MainPage'

const localStorageAuthContextBackend = new LocalStorageAuthContextBackend('skeema_auth_token')
const webappExtensionHealthContextBackend = new WebAppExtensionHealthContextBackend()
const localStorageFeatureFlagContextBackend = new LocalStorageFeatureFlagContextBackend()

const WebApp: FC = () => {
  if (isMobile) {
    return (
      <ChakraProvider theme={chakraTheme}>
        <MobileAnnouncementsPage
          heading={"We don't support mobile.  For now. 😉"}
          content={['Please use your desktop browser to access Skipper.']}
        />
      </ChakraProvider>
    )
  }

  return (
    <GoogleOAuthProvider clientId={OAUTH_WEB_CLIENT_ID}>
      <ChakraProvider theme={chakraTheme}>
        <ReduxProvider store={webappStore}>
          <AuthContextProvider backend={localStorageAuthContextBackend}>
            <BrowserRouter>
              <ExtensionHealthContextProvider backend={webappExtensionHealthContextBackend}>
                <UserContextProvider isInExtension={false}>
                  <BrandingContextProvider>
                    <FeatureFlagContextProvider backend={localStorageFeatureFlagContextBackend}>
                      <DndProvider backend={HTML5Backend}>
                        <MainPage />
                      </DndProvider>
                    </FeatureFlagContextProvider>
                  </BrandingContextProvider>
                </UserContextProvider>
              </ExtensionHealthContextProvider>
            </BrowserRouter>
          </AuthContextProvider>
        </ReduxProvider>
      </ChakraProvider>
    </GoogleOAuthProvider>
  )
}

export default WebApp
