import React, { FC } from 'react'
import {
  Flex,
  Box,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Card,
  Stack,
  StackDivider,
  Heading,
  Text,
  HStack,
  Button,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
import { HiSparkles } from 'react-icons/hi2'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import PaymentPlanBadge from './PaymentPlanBadge'
import { modalCloseButtonClass } from './PaymentFlowModal'
import { useLocalStorageObject } from '../../../utils/genericHooks'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'

enum FEATURE_VOTES_ENUM {
  ADAPTIVE_SAVE = 'adaptiveAutomaticSave',
  LIVE_PROJECTS = 'liveProjects',
}

type FeatureVotesType = {
  [key in FEATURE_VOTES_ENUM]?: boolean
}

interface FeatureType {
  name: string
  description?: string
  isAI?: boolean
}

interface UpcomingFeatureType extends FeatureType {
  key: FEATURE_VOTES_ENUM
}

const UPCOMING_FEATURES: UpcomingFeatureType[] = [
  {
    name: 'Adaptive Automatic Save',
    description: 'Intelligently keep relevant tabs open',
    isAI: true,
    key: FEATURE_VOTES_ENUM.ADAPTIVE_SAVE,
  },
  {
    name: 'Live Projects',
    description: 'Intelligently update Projects',
    isAI: true,
    key: FEATURE_VOTES_ENUM.LIVE_PROJECTS,
  },
]

interface Props {
  onClickUpgrade: () => void
  onClickManagePlan: () => void
  isPremium: boolean
  isUserOnAnnualPlan: boolean
  isAnnualPlanToggled: boolean
  setIsAnnualPlanToggled: React.Dispatch<React.SetStateAction<boolean>>
  isActionButtonLoading: boolean
}

const PricingPlansModalContent: FC<Props> = (props) => {
  const {
    onClickUpgrade,
    onClickManagePlan,
    isPremium,
    isUserOnAnnualPlan,
    isAnnualPlanToggled,
    setIsAnnualPlanToggled,
    isActionButtonLoading,
  } = props
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()

  const shouldHaveUnlimitedInstantSaves =
    featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldHaveUnlimitedInstantSave]

  const basic_features: FeatureType[] = [
    { name: 'Configurable Automatic Save' },
    shouldHaveUnlimitedInstantSaves
      ? { name: 'Unlimited Instant Saves' }
      : { name: '5 Instant Saves per week' },
    {
      name: 'Unlimited Sessions',
      isAI: true,
    },
    { name: 'Keyword search' },
    { name: 'Up to 10 Projects' },
    { name: 'Up to 10 Tabs per Project' },
    { name: '30 days of history' },
  ]

  const pro_features: FeatureType[] = [
    ...(shouldHaveUnlimitedInstantSaves ? [] : [{ name: 'Unlimited Instant Saves' }]),
    ...[
      { name: 'Up to 100 Projects' },
      { name: 'Up to 100 items per Project' },
      {
        name: 'All time history',
        description: 'Access full saved tabs history',
      },
      { name: 'Access new features first' },
      { name: 'Chat directly with the founders' },
    ],
  ]

  const isAnnualPlanShown = isUserOnAnnualPlan || (!isPremium && isAnnualPlanToggled)
  const [featureVotes, setFeatureVotes] = useLocalStorageObject<FeatureVotesType>(
    'featureVotes',
    {},
  )

  const handleClickFeatureVote = (key: FEATURE_VOTES_ENUM) => {
    const newVal = !featureVotes?.[key]
    setFeatureVotes({
      ...featureVotes,
      [key]: newVal,
    })
    captureAnalytics('payment_flow:pricing_modal_feature_vote_click', {
      feature: key,
      isVoteYes: newVal,
    })
  }

  const handleClickAnnualPlan = () => {
    setIsAnnualPlanToggled(true)
    captureAnalytics('payment_flow:pricing_modal_annual_plan_click')
  }
  const handleClickMonthlyPlan = () => {
    setIsAnnualPlanToggled(false)
    captureAnalytics('payment_flow:pricing_modal_monthly_plan_click')
  }

  const currentPlanElement = (
    <Text
      ml="8px"
      color="#585858"
      fontWeight={500}
      fontSize="14px"
      lineHeight="22px"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      👈 Your current plan
    </Text>
  )

  return (
    <ModalContent p="32px" borderRadius="16px" width="auto">
      <ModalHeader p="0" mb="12px">
        <Heading
          fontSize="28px"
          fontWeight={500}
          color="#000"
          lineHeight="32px"
          letterSpacing="-1px"
        >
          Our Plans
        </Heading>
      </ModalHeader>
      <ModalCloseButton className={modalCloseButtonClass} />

      <ModalBody p="0" overflow="hidden">
        {!isPremium && (
          <Flex w="100%" alignItems="center" justifyContent="flex-end" mb="16px">
            <Flex borderRadius="24px" bg="#f6f6f6" p="4px">
              <Button
                bg={!isAnnualPlanToggled ? 'black' : '#f6f6f6'}
                color={!isAnnualPlanToggled ? 'white' : '#585858'}
                borderRadius="100px"
                p="4px 14px"
                height="30px"
                mr="4px"
                fontWeight={500}
                fontSize={'14px'}
                lineHeight={'22px'}
                _hover={{ bg: `${!isAnnualPlanToggled ? 'black' : 'transparent'}` }}
                onClick={handleClickMonthlyPlan}
                autoFocus
              >
                Monthly
              </Button>
              <Button
                bg={isAnnualPlanToggled ? 'black' : '#f6f6f6'}
                color={isAnnualPlanToggled ? 'white' : '#585858'}
                borderRadius="100px"
                p="4px 14px"
                height="30px"
                fontWeight={500}
                fontSize={'14px'}
                lineHeight={'22px'}
                _hover={{ bg: `${isAnnualPlanToggled ? 'black' : 'transparent'}` }}
                onClick={handleClickAnnualPlan}
                autoFocus
              >
                Yearly
                <Text
                  ml="6px"
                  lineHeight="16px"
                  fontSize="12px"
                  color={isAnnualPlanToggled ? 'white' : '#0071E3'}
                >
                  save 20%
                </Text>
              </Button>
            </Flex>
          </Flex>
        )}
        <HStack alignItems="top" gap="16px">
          <Card
            w="280px"
            p="24px"
            borderRadius="16px"
            border="1px solid #D5D5D5"
            overflow="hidden"
            variant="outline"
          >
            <Flex alignItems="center" mb="24px">
              <PaymentPlanBadge plan="basic" />
              {!isPremium && currentPlanElement}
            </Flex>
            <Stack divider={<StackDivider borderColor="#D5D5D5" />} spacing="24px">
              <Box>
                <Heading
                  fontSize={'28px'}
                  fontWeight={500}
                  color={'black'}
                  lineHeight={'32px'}
                  letterSpacing={'-1px'}
                >
                  Free
                </Heading>
                <Text mt="8px" fontSize="12px" color="black">
                  All the essentials for a more focused online experience
                </Text>
                <Box h="52px" />
              </Box>
              <Stack gap="8px">
                {basic_features.map((feature, i) => (
                  <Box key={i}>
                    <Flex gap="4px" alignItems={'center'}>
                      <MdCheckCircle style={{ color: '#585858' }} />
                      <Text fontSize="12px" fontWeight="500" color="#585858">
                        {feature.name}
                      </Text>
                      {feature.isAI && <Icon as={HiSparkles} />}
                    </Flex>
                    {feature.description && (
                      <Text ml="20px" fontSize="12px" color="#A7A7A7">
                        {feature.description}
                      </Text>
                    )}
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Card>

          <Card
            w="280px"
            h="fit-content"
            p="24px"
            borderRadius="16px"
            border="1px solid #D5D5D5"
            overflow="hidden"
            variant="outline"
          >
            <Flex alignItems="center" mb="24px">
              <PaymentPlanBadge plan="pro" />
              {isPremium && currentPlanElement}
            </Flex>
            <Stack divider={<StackDivider borderColor="#D5D5D5" />} spacing="24px">
              <Box>
                <Flex alignItems="flex-end">
                  {isAnnualPlanShown && (
                    <Heading
                      mr="6px"
                      fontSize={'28px'}
                      fontWeight={500}
                      color={'#D5D5D5'}
                      lineHeight={'32px'}
                      letterSpacing={'-1px'}
                      textDecoration={'line-through'}
                    >
                      $5
                    </Heading>
                  )}
                  <Heading
                    fontSize={'28px'}
                    fontWeight={500}
                    color={'black'}
                    lineHeight={'32px'}
                    letterSpacing={'-1px'}
                  >
                    {isAnnualPlanShown ? '$4/mo' : '$5/mo'}
                  </Heading>
                  <Text ml="6px" color="#A7A7A7" fontSize="12px" fontWeight={500} lineHeight="20px">
                    {isAnnualPlanShown ? 'billed yearly' : 'billed monthly'}
                  </Text>
                </Flex>
                <Text mt="8px" mb="16px" fontSize="12px" color="black">
                  For professionals seeking effective and effortless digital organization
                </Text>

                {isPremium ? (
                  <Button
                    bg="white"
                    border="1px solid black"
                    color="black"
                    borderRadius="100px"
                    w="100%"
                    height="36px"
                    _hover={{ bg: '#F1F1F1' }}
                    onClick={onClickManagePlan}
                    isLoading={isActionButtonLoading}
                    autoFocus
                  >
                    Manage Plan
                  </Button>
                ) : (
                  <Button
                    bg="black"
                    color="white"
                    borderRadius="100px"
                    w="100%"
                    height="36px"
                    _hover={{ bg: '#585858' }}
                    onClick={onClickUpgrade}
                    isLoading={isActionButtonLoading}
                    autoFocus
                  >
                    Upgrade
                  </Button>
                )}
              </Box>
              <Stack gap="8px">
                <Flex gap="4px" alignItems={'center'}>
                  <MdCheckCircle style={{ color: '#585858' }} />
                  <Text fontSize="12px" fontWeight="500" color="#585858">
                    Everything in Basic
                  </Text>
                </Flex>
                {pro_features.map((feature, i) => (
                  <Box key={i}>
                    <Flex gap="4px" alignItems={'center'}>
                      <MdCheckCircle style={{ color: '#0C894E' }} />
                      <Text fontSize="12px" fontWeight="500" color="#585858">
                        {feature.name}
                      </Text>
                      {feature.isAI && <Icon as={HiSparkles} />}
                    </Flex>
                    {feature.description && (
                      <Text ml="20px" fontSize="12px" color="#A7A7A7">
                        {feature.description}
                      </Text>
                    )}
                  </Box>
                ))}
              </Stack>
            </Stack>

            <Heading as="h3" fontSize="14px" fontWeight={500} color="#585858" mt="24px" mb="4px">
              Which upcoming Pro feature should we build first?
            </Heading>
            <Stack>
              {UPCOMING_FEATURES.map((feature) => {
                return (
                  <Flex key={feature.key} mt="8px" align="start">
                    <IconButton
                      className="hide-show-hover-container"
                      size="2xs"
                      bg="transparent"
                      mt="2px"
                      color={featureVotes?.[feature.key] ? 'black' : '#D5D5D5'}
                      _hover={{
                        color: featureVotes?.[feature.key] ? '#585858' : '#D5D5D5',
                      }}
                      icon={
                        <Box position="relative" w="16px">
                          <AiFillHeart
                            className={`show-on-hover ${
                              featureVotes?.[feature.key] ? 'force-show' : ''
                            }`}
                          />
                          <AiOutlineHeart
                            className={`hide-on-hover ${
                              featureVotes?.[feature.key] ? 'force-hide' : ''
                            }`}
                          />
                        </Box>
                      }
                      onClick={() => {
                        handleClickFeatureVote(feature.key)
                      }}
                      aria-label="Upvote"
                    />
                    <Box ml={1}>
                      <Flex gap="4px" alignItems={'center'}>
                        <Text fontSize="12px" fontWeight="500" color="#585858">
                          {feature.name}
                        </Text>
                        {feature.isAI && <Icon as={HiSparkles} />}
                      </Flex>
                      {feature.description && (
                        <Text fontSize="12px" color="#A7A7A7">
                          {feature.description}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                )
              })}
            </Stack>
          </Card>
        </HStack>
      </ModalBody>
    </ModalContent>
  )
}

export default PricingPlansModalContent
