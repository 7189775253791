import React, { FC } from 'react'
import { Tooltip, IconButton } from '@chakra-ui/react'
import { css, styled } from 'styled-components'
import { MdOpenInNew } from 'react-icons/md'
import DragIcon from '../../../../components/DragIcon'
import { FaRegFolderOpen } from 'react-icons/fa'

const HoverButtonsContainer = styled.div<{
  $isProjectExpanded: boolean
}>`
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background: ${({ $isProjectExpanded }) => ($isProjectExpanded ? 'white' : '#ebebeb')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 100%;
    background-image: ${({ $isProjectExpanded }) =>
      $isProjectExpanded
        ? 'linear-gradient(to right, rgba(255, 255, 255, 0), white)'
        : 'linear-gradient(to right, rgba(235, 235, 235, 0), #ebebeb)'};
  }
`

const TitleContainer = styled.div<{
  $shouldForceHoverState: boolean
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  // Hovered & expanded states are managed in ProjectSidebarItem.tsx
  & > h2 {
    color: #585858;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    cursor: pointer;
  }

  .hover-show-project-title {
    transition: opacity 0.2s;
    opacity: 0;
  }

  ${({ $shouldForceHoverState }) =>
    $shouldForceHoverState
      ? css`
          .hover-show-project-title {
            opacity: 1;
          }
        `
      : ''}
`

interface Props {
  title: string
  handleClickOpenIcon: (event: React.MouseEvent<Element, MouseEvent>) => void
  handleClickOpenDetailView: (event: React.MouseEvent<Element, MouseEvent>) => void
  isProjectExpanded: boolean
}

const StaticProjectTitle: FC<Props> = (props) => {
  const { title, handleClickOpenIcon, handleClickOpenDetailView, isProjectExpanded } = props

  return (
    <TitleContainer $shouldForceHoverState={false}>
      <span style={{ width: '12px' }} className="hover-show-project-title">
        <DragIcon />
      </span>

      <h2>{title}</h2>

      <HoverButtonsContainer
        className="hover-show-project-title"
        $isProjectExpanded={isProjectExpanded}
      >
        <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
          <Tooltip label={`Open all pages`} placement="top">
            <IconButton
              variant="outline"
              border="none"
              aria-label={`Open all pages`}
              as="a"
              icon={<MdOpenInNew color="#585858" />}
              onClick={handleClickOpenIcon}
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{
                background: 'inherit',
              }}
            />
          </Tooltip>
        </span>
        <span
          className="flex-center"
          style={{ height: '100%', cursor: 'pointer', marginLeft: '2px' }}
        >
          <Tooltip label={`Expand Project`} placement="top">
            <IconButton
              variant="outline"
              border="none"
              aria-label={`Expand Project`}
              icon={<FaRegFolderOpen color="#585858" />}
              onClick={handleClickOpenDetailView}
              height="24px"
              width="24px"
              minWidth="24px"
              tabIndex={-1}
              _hover={{
                background: 'inherit',
              }}
            />
          </Tooltip>
        </span>
      </HoverButtonsContainer>
    </TitleContainer>
  )
}

export default StaticProjectTitle
