import React, { FC } from 'react'
import MainDashboard, { CONTENT_TYPE } from './MainDashboard'
import { useParams } from 'react-router-dom'
import SharedProjectPage from './SharedProjectPage'
import { useGetProjectQuery } from '../../redux/services/skeema/projects.endpoints'
import { useReduxSelector } from '../../redux/baseStore'
import { useExtensionHealthContext } from '../../contexts/ExtensionHealthContext'
import NotFoundPage from '../notFound/NotFoundPage'
import LoadingPage from './LoadingPage'
import NotificationBannerWrapper from './NotificationBannerWrapper'

interface Props {
  contentType: CONTENT_TYPE
}

const MorningPage: FC<Props> = (props) => {
  const { contentType } = props
  const extensionHealth = useExtensionHealthContext().extensionHealth
  const isExtensionNotInstalled = extensionHealth === null
  const isAuthLoading = useReduxSelector((state) => state.user.isAuthLoading)
  const { projectId } = useParams<{ projectId: string }>()
  const { data: project, error: projectQueryError } = useGetProjectQuery(
    {
      id: String(projectId),
    },
    {
      skip: !projectId || isAuthLoading,
    },
  )

  if (contentType === 'project') {
    if (
      projectQueryError &&
      (('status' in projectQueryError && projectQueryError.status === 404) ||
        ('originalStatus' in projectQueryError && projectQueryError.originalStatus === 500)) //500 can occur for invalid UUIDs
    ) {
      return <NotFoundPage />
    }

    if (!project) {
      return <LoadingPage />
    }

    if (!project.is_owner || isExtensionNotInstalled) {
      return <SharedProjectPage project={project} />
    }
  }

  return (
    <NotificationBannerWrapper>
      <MainDashboard contentType={contentType} />
    </NotificationBannerWrapper>
  )
}

export default MorningPage
