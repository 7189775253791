import React, { FC } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useBrandingContext } from '../../../contexts/BrandingContext'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingPrefaceDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { APP_NAME } = useBrandingContext()

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={`${APP_NAME} keeps your tabs organized. Always.`}
      buttonText={`Next`}
      onButtonClick={onNext}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingPrefaceDialog
