import React, { FC, useCallback, useEffect, useState } from 'react'

import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import {
  useGetAIActionLogsQuery,
  useLazyGetAIActionLogsQuery,
} from '../../redux/services/skeema/feed_history.endpoints'
import HistoryItemCard from './feedHistory/HistoryItemCard'
import { AIActionLogType } from '../../models/ai_action_logs.types'

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const FeedHistoryDashboard: FC = () => {
  const [items, setItems] = useState<AIActionLogType[]>([])
  const [nextPage, setNextPage] = useState<number | undefined>(undefined)

  const {
    data: firstPageData,
    isLoading: isFirstPageLoading,
    isSuccess: isFirstPageSuccess,
  } = useGetAIActionLogsQuery({})
  useEffect(() => {
    if (isFirstPageSuccess) {
      setItems(firstPageData?.results || [])
      if (firstPageData?.next) {
        setNextPage(parseInt(firstPageData.next.split('?page=')[1]))
      } else {
        setNextPage(-1)
      }
    }
  }, [isFirstPageSuccess, firstPageData])

  const [loadMoreItems, loadMoreItemsResult] = useLazyGetAIActionLogsQuery({
    refetchOnFocus: false,
  })
  const onClickShowOlder = useCallback(() => {
    loadMoreItems({ page: nextPage })
  }, [loadMoreItems, nextPage])
  useEffect(() => {
    if (loadMoreItemsResult.isSuccess) {
      const newItems = loadMoreItemsResult.data?.results || []
      setItems((prevItems) => {
        return [...prevItems, ...newItems]
      })
      if (loadMoreItemsResult.data?.next) {
        setNextPage(parseInt(loadMoreItemsResult.data.next.split('?page=')[1]))
      } else {
        setNextPage(-1)
      }
    }
  }, [loadMoreItemsResult])

  return (
    <Box p="0px 16px 24px 8px">
      {isFirstPageLoading && (
        <div className="flex-center" style={{ width: '100%', margin: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}
      {isFirstPageSuccess && items.length === 0 && (
        <div className="flex-center" style={{ width: '100%' }}>
          <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
            {`No history to show at the moment.  Check back later!`}
          </Text>
        </div>
      )}
      <CardListContainer>
        {items.map((action, idx) => (
          <HistoryItemCard key={`${idx}`} action={action} />
        ))}
      </CardListContainer>
      {nextPage !== undefined && (
        <Flex w="100%" alignItems="center" justifyContent="flex-end">
          {nextPage > 0 && (
            <Button
              key="extra"
              size="sm"
              fontSize={12}
              fontWeight={500}
              borderRadius={'16px'}
              h="32px"
              minH="32px"
              w="100%"
              px={2}
              onClick={onClickShowOlder}
              bg="#F6F6F6"
              color="#585858"
              _hover={{ bg: '#EBEBEB' }}
              isLoading={loadMoreItemsResult.isLoading}
            >
              {`Show older`}
            </Button>
          )}
          {nextPage === -1 && items && items.length > 0 && (
            <Text
              fontSize={12}
              fontWeight={500}
              mt="8px"
              w="100%"
              px={2}
              color="#A7A7A7"
              textAlign="center"
            >
              {`You've reached the end of your history`}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default FeedHistoryDashboard
