import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'

import searchReducer from './searchSlice'
import userReducer from './userSlice'
import dndReducer from './dndSlice'
import projectsReducer from './projectsSlice'
import tablistReducer from './tablistSlice'
import { skeemaApi } from './services/skeema'
import { extensionMessageApi } from '../webapp/redux/extension'

// WARNING: This store is used to generate types, it should not be used in any context
const _baseStore = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    dnd: dndReducer,
    projects: projectsReducer,
    tablist: tablistReducer,
    [skeemaApi.reducerPath]: skeemaApi.reducer,
    [extensionMessageApi.reducerPath]: extensionMessageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(skeemaApi.middleware).concat(extensionMessageApi.middleware),
})

export type ReduxStateType = ReturnType<typeof _baseStore.getState>
export type ReduxDispatchType = typeof _baseStore.dispatch
export const useReduxDispatch = useDispatch.withTypes<ReduxDispatchType>()
export const useReduxSelector: TypedUseSelectorHook<ReduxStateType> = useSelector
