import { skeemaApi } from '../skeema'
import {
  OMNI_SEARCH_ENDPOINT_PATH,
  OmniSearchEndpointType,
  TABLIST_PAGES_ENDPOINT_PATH,
  TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH,
  TABLIST_PAGES_SEARCH_ENDPOINT_PATH,
  TablistPagesEndpointType,
  TablistPagesRecentlyUsedEndpointType,
  TablistPagesSearchEndpointType,
} from '../../../models/endpoints.types'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../../models/tablist_pages.types'
import { decInstantSaveQuota, incInstantSaveQuota, setInstantSaveQuota } from '../../userSlice'
import { createSelector } from '@reduxjs/toolkit'
import { optimisticRemoveOpenTab } from '../../../webapp/redux/extension'
import { deletePage, replacePage, savePage } from '../../tablistSlice'

export const tablistPagesApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getTablistPages: builder.query<TablistPagesEndpointType['GET']['response'], undefined>({
      query: () => {
        return {
          url: `${TABLIST_PAGES_ENDPOINT_PATH}?should_include_most_visited=${true}`,
          method: 'GET',
        }
      },
    }),
    getOlderSavedPages: builder.query<
      TablistPagesEndpointType['GET']['response'],
      TablistPagesEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const { to_ts, should_include_most_visited = false } = params

        return {
          url: `${TABLIST_PAGES_ENDPOINT_PATH}?to_ts=${to_ts}&should_include_most_visited=${should_include_most_visited}`,
          method: 'GET',
        }
      },
    }),
    searchTablistPages: builder.query<
      TablistPagesSearchEndpointType['GET']['response'],
      TablistPagesSearchEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const { to_ts, query } = params

        return {
          url: `${TABLIST_PAGES_SEARCH_ENDPOINT_PATH}?query=${query}&to_ts=${to_ts}`,
          method: 'GET',
        }
      },
    }),
    omnisearch: builder.query<
      OmniSearchEndpointType['GET']['response'],
      OmniSearchEndpointType['GET']['queryParams']
    >({
      query: (params) => {
        const { query, page } = params
        return {
          url: `${OMNI_SEARCH_ENDPOINT_PATH}?query=${query}&page=${page}`,
          method: 'GET',
        }
      },
    }),
    deleteTablistPage: builder.mutation<
      TablistPagesRecentlyUsedEndpointType['DELETE']['response'],
      { page: TablistPageType; index: number }
    >({
      query: ({ page }) => {
        const { id } = page
        return {
          url: `${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}${id}/`,
          method: 'DELETE',
        }
      },
      onQueryStarted: async ({ page, index }, { dispatch, queryFulfilled }) => {
        const { id } = page
        const undoPayload = { index, page }

        dispatch(deletePage(id))
        const response = dispatch(
          tablistPagesApi.util.updateQueryData(
            'getTablistPages',
            undefined,
            (draft: TablistPagesEndpointType['GET']['response']) => {
              const idx = draft.tablist.findIndex((page) => page.id === id)
              if (idx !== -1) {
                draft.tablist.splice(idx, 1)
              }
            },
          ),
        )

        await queryFulfilled.catch(() => {
          response.undo()
          if (undoPayload) {
            dispatch(savePage(undoPayload))
          }
        })
      },
    }),
    appendTablistPages: builder.mutation<
      {
        ok: boolean
        details: { data: TablistPageType[]; remaining_quota: number } | { error: string }
      },
      { page: TablistPageType; isManual: boolean }
    >({
      query: ({ page, isManual }) => {
        const tab = {
          tab_id: page.id,
          url: page.url,
          title: page.title,
          favicon_url: page.favicon_url,
          last_access_time_ms: page.last_access_timestamp_ms,
          window_id: page.window_id ?? -1,
        }
        const data = {
          tablist: [tab],
          is_manual: isManual,
        }
        return {
          url: `${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}`,
          method: 'POST',
          body: JSON.stringify(data),
        }
      },
      onQueryStarted: async ({ page, isManual }, { dispatch, queryFulfilled }) => {
        const newPage: TablistPageType = {
          ...page,
          entity_type: TABLIST_PAGE_ENUM.RECENTLY_USED,
          is_pinned: false,
          is_manual: isManual,
        }
        const optimisticRemoveOpenTabResult = optimisticRemoveOpenTab(dispatch, page.id)
        const optimisticTablistUpdate = dispatch(
          tablistPagesApi.util.updateQueryData(
            'getTablistPages',
            undefined,
            (draft: TablistPagesEndpointType['GET']['response']) => {
              draft.tablist.unshift(newPage)
            },
          ),
        )
        dispatch(savePage({ page: newPage }))
        dispatch(decInstantSaveQuota(undefined))
        try {
          const { data: response } = await queryFulfilled

          if (!response.ok) {
            const details = response.details as { error: string }
            if (details.error === 'QUOTA_EXCEEDED') {
              optimisticRemoveOpenTabResult.undo()
              optimisticTablistUpdate.undo()
              dispatch(deletePage(page.id))
              dispatch(setInstantSaveQuota(0))
              return
            }
            throw new Error(details.error)
          }

          const { data: upsertedEntities, remaining_quota } = response.details as {
            data: TablistPageType[]
            remaining_quota: number
          }
          dispatch(
            tablistPagesApi.util.updateQueryData(
              'getTablistPages',
              undefined,
              (draft: TablistPagesEndpointType['GET']['response']) => {
                draft.tablist[0] = upsertedEntities[0]
              },
            ),
          )
          dispatch(replacePage({ id: page.id, newPage: upsertedEntities[0] }))
          dispatch(setInstantSaveQuota(remaining_quota))
        } catch (e) {
          optimisticRemoveOpenTabResult.undo()
          optimisticTablistUpdate.undo()
          dispatch(deletePage(page.id))
          dispatch(incInstantSaveQuota(undefined))
        }
      },
    }),
  }),
})

const selectGetTablistPagesQueryResult = tablistPagesApi.endpoints.getTablistPages.select(undefined)
export const selectMostVisitedPages = createSelector(selectGetTablistPagesQueryResult, (result) => {
  return result.data?.most_visited_pages
})
export const selectPutasidePages = createSelector(selectGetTablistPagesQueryResult, (result) => {
  return result.data?.tablist
})

export const {
  useGetTablistPagesQuery,
  useLazyGetTablistPagesQuery,
  useLazyGetOlderSavedPagesQuery,
  useLazySearchTablistPagesQuery,
  useLazyOmnisearchQuery,
  useDeleteTablistPageMutation,
  useAppendTablistPagesMutation,
} = tablistPagesApi

export type LazySearchTablistPagesType = ReturnType<typeof useLazySearchTablistPagesQuery>
export type LazyOmniSearchType = ReturnType<typeof useLazyOmnisearchQuery>
