import React, { useState, useContext, createContext, FC, useEffect } from 'react'
import { ExtensionHealthType, useExtensionHealthContext } from './ExtensionHealthContext'
import {
  APP_LOGO_WORDMARK_URL_LOADING,
  APP_LOGO_WORDMARK_URL_SKEEMA,
  APP_LOGO_WORDMARK_URL_SKIPPER,
  APP_NAME_SKEEMA,
  APP_NAME_SKIPPER,
  FEEDBACK_FORM_URL_SKEEMA,
  FEEDBACK_FORM_URL_SKIPPER,
  HOW_TO_URL_SKEEMA,
  HOW_TO_URL_SKIPPER,
  INSTANT_SAVE_MAC_IMG_URL_SKEEMA,
  INSTANT_SAVE_MAC_IMG_URL_SKIPPER,
  INSTANT_SAVE_WINDOWS_IMG_URL_SKEEMA,
  INSTANT_SAVE_WINDOWS_IMG_URL_SKIPPER,
  SUPPORT_EMAIL_SKEEMA,
  SUPPORT_EMAIL_SKIPPER,
  UNINSTALL_FEEDBACK_FORM_URL_SKEEMA,
  UNINSTALL_FEEDBACK_FORM_URL_SKIPPER,
  AUTH_REDIRECT_URL_SKEEMA,
  AUTH_REDIRECT_URL_SKIPPER,
} from '../constants'
import { isMac } from '../utils/utils'
import { useUserContext } from './UserContext'
import { useLocalStorageBoolean } from '../utils/genericHooks'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../models/browserStorage.types'

type BrandingContextType = {
  isSkipper: boolean | undefined // undefined means that the branding is being loaded
  FEEDBACK_FORM_URL: string
  HOW_TO_URL: string
  UNINSTALL_FEEDBACK_FORM_URL: string
  APP_NAME: string
  APP_LOGO_WORDMARK_URL: string
  INSTANT_SAVE_IMG_URL: string
  SUPPORT_EMAIL: string
  AUTH_REDIRECT_URL: string
}

const IS_MAC = isMac()

const SKEEMA_VALUES = {
  isSkipper: false,
  FEEDBACK_FORM_URL: FEEDBACK_FORM_URL_SKEEMA,
  HOW_TO_URL: HOW_TO_URL_SKEEMA,
  UNINSTALL_FEEDBACK_FORM_URL: UNINSTALL_FEEDBACK_FORM_URL_SKEEMA,
  APP_NAME: APP_NAME_SKEEMA,
  APP_LOGO_WORDMARK_URL: APP_LOGO_WORDMARK_URL_SKEEMA,
  INSTANT_SAVE_IMG_URL: IS_MAC
    ? INSTANT_SAVE_MAC_IMG_URL_SKEEMA
    : INSTANT_SAVE_WINDOWS_IMG_URL_SKEEMA,
  SUPPORT_EMAIL: SUPPORT_EMAIL_SKEEMA,
  AUTH_REDIRECT_URL: AUTH_REDIRECT_URL_SKEEMA,
}

const SKIPPER_VALUES = {
  isSkipper: true,
  FEEDBACK_FORM_URL: FEEDBACK_FORM_URL_SKIPPER,
  HOW_TO_URL: HOW_TO_URL_SKIPPER,
  UNINSTALL_FEEDBACK_FORM_URL: UNINSTALL_FEEDBACK_FORM_URL_SKIPPER,
  APP_NAME: APP_NAME_SKIPPER,
  APP_LOGO_WORDMARK_URL: APP_LOGO_WORDMARK_URL_SKIPPER,
  INSTANT_SAVE_IMG_URL: IS_MAC
    ? INSTANT_SAVE_MAC_IMG_URL_SKIPPER
    : INSTANT_SAVE_WINDOWS_IMG_URL_SKIPPER,
  SUPPORT_EMAIL: SUPPORT_EMAIL_SKIPPER,
  AUTH_REDIRECT_URL: AUTH_REDIRECT_URL_SKIPPER,
}

const LOADING_VALUES = {
  ...SKIPPER_VALUES,
  APP_LOGO_WORDMARK_URL: APP_LOGO_WORDMARK_URL_LOADING,
  isSkipper: undefined,
}

const getBrandingValues = (
  extensionHealth: ExtensionHealthType | undefined | null,
): BrandingContextType => {
  if (extensionHealth === undefined) {
    //Waiting for extension health to load
    return LOADING_VALUES
  }

  if (extensionHealth === null) {
    //Extension not installed
    return SKIPPER_VALUES
  }

  if (extensionHealth.version >= 74) {
    //Skipper extension is installed
    return SKIPPER_VALUES
  }

  //Skeema extension is installed
  return SKEEMA_VALUES
}

const BrandingContext = createContext<BrandingContextType>(LOADING_VALUES)

export const useBrandingContext = (): BrandingContextType => {
  return useContext(BrandingContext)
}

export const BrandingContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { extensionHealth } = useExtensionHealthContext()
  const { captureAnalytics } = useUserContext()
  const [value, setValue] = useState<BrandingContextType>(LOADING_VALUES)
  const [hasSkipperBrandingLoaded, setHasSkipperBrandingLoaded] = useLocalStorageBoolean(
    WEBAPP_LOCAL_STORAGE_KEYS.HasSkipperBrandingLoaded,
    false,
  )

  useEffect(() => {
    if (value.isSkipper === undefined) {
      return
    }

    if (hasSkipperBrandingLoaded && value.isSkipper === false) {
      //Should never happen
      captureAnalytics('branding_context_provider:revert', {
        extensionVersion: extensionHealth ? extensionHealth.version : extensionHealth,
      })
      setHasSkipperBrandingLoaded(false)
      return
    }

    if (!hasSkipperBrandingLoaded && value.isSkipper) {
      //Skipper branding is loaded
      captureAnalytics('branding_context_provider:load', {
        extensionVersion: extensionHealth ? extensionHealth.version : extensionHealth,
        isSkipper: value.isSkipper,
      })
      setHasSkipperBrandingLoaded(true)
      return
    }
  }, [
    captureAnalytics,
    extensionHealth,
    value.isSkipper,
    hasSkipperBrandingLoaded,
    setHasSkipperBrandingLoaded,
  ])

  useEffect(() => {
    const newVal = getBrandingValues(extensionHealth)
    setValue(newVal)
    window.document.title = newVal.APP_NAME
  }, [extensionHealth])

  return <BrandingContext.Provider value={value}>{children}</BrandingContext.Provider>
}
