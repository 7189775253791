import React from 'react'
import { useField } from 'formik'
import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react'

interface DropDownMenuProps {
  name: string
  label: string
  placeholder: string
  options: {
    value: string
    label: string
  }[]
}

const DropDownMenu = (props: DropDownMenuProps) => {
  const { name, label, placeholder, options } = props
  const [field, meta] = useField({ name })
  const { value, error, touched } = meta

  return (
    <FormControl id={name} isInvalid={!!error && !!touched}>
      <FormLabel
        htmlFor={name}
        color={'#585858'}
        fontSize={'14px'}
        fontWeight={'500'}
        lineHeight={'22px'}
      >
        {label}
      </FormLabel>
      <Select
        {...field}
        placeholder={placeholder}
        size="xs"
        borderColor="#D5D5D5"
        borderRadius={'8px'}
        color={value ? 'black' : '#A7A7A7'}
        fontSize={'12px'}
        height="26px"
        maxWidth={'400px'}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default DropDownMenu
