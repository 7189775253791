import React, { FC, useEffect, useState } from 'react'
import {
  Switch,
  Flex,
  Text,
  Button,
  Divider,
  Heading,
  Icon,
  Spacer,
  Wrap,
  Spinner,
} from '@chakra-ui/react'
import { ExtensionSettingsType } from '../../../extension/utils/settingsUtils'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { FormikProvider, useFormik } from 'formik'
import { useUserContext } from '../../../contexts/UserContext'
import { HiSparkles } from 'react-icons/hi2'
import AutoSaveSettingsFormContent from './AutoSaveSettingsFormContent'
import { useBrandingContext } from '../../../contexts/BrandingContext'

export enum SETTINGS_FORM_FIELD_NAMES {
  STALE_THRESHOLD_AFTER_OVERWHELM = 'staleThresholdAfterOverwhelm',
  OVERWHELM_THRESHOLD = 'overwhelmThreshold',
}
interface SettingsFormValuesType {
  [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: string | null
  [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: string | null
}

enum ButtonState {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Saved = 'saved',
}

interface Props {
  isInternal: boolean
}

const SettingsForm: FC<Props> = (props) => {
  const { isInternal } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled)
  const { captureAnalytics } = useUserContext()
  const { FEEDBACK_FORM_URL, APP_NAME } = useBrandingContext()

  const [isAutomaticSaveEnabled, setIsAutomaticSaveEnabled] = useState<boolean | undefined>(
    undefined,
  )
  const handleAutomaticSaveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updateSettings = async (checked: boolean) => {
      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings: {
          isAutoSaveEnabled: checked,
        },
      })
      setIsAutomaticSaveEnabled(checked)
    }
    updateSettings(e.target.checked)
  }

  const formik = useFormik<SettingsFormValuesType>({
    initialValues: {
      [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: null,
      [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: null,
    },
    onSubmit: (values) => {
      const settings = {
        [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]: Number(
          values[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM],
        ),
        [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]: Number(
          values[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD],
        ),
      } as Partial<ExtensionSettingsType>
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
        settings,
      })
      formik.resetForm({ values })
      captureAnalytics('settings_page:save_button_click', settings)
      setButtonState(ButtonState.Saved)
      setTimeout(() => {
        setButtonState((prev) => (prev === ButtonState.Saved ? ButtonState.Disabled : prev))
      }, 3000)
    },
  })
  const { dirty, resetForm } = formik

  useEffect(() => {
    setIsLoading(true)
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        const values = {
          [SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM]:
            settings[SETTINGS_FORM_FIELD_NAMES.STALE_THRESHOLD_AFTER_OVERWHELM].toString(),
          [SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD]:
            settings[SETTINGS_FORM_FIELD_NAMES.OVERWHELM_THRESHOLD].toString(),
        }
        resetForm({
          values,
        })
        setIsAutomaticSaveEnabled(settings.isAutoSaveEnabled)
        setIsLoading(false)
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
      })
  }, [resetForm])

  useEffect(() => {
    if (dirty) {
      setButtonState(ButtonState.Enabled)
    } else {
      if (buttonState !== ButtonState.Saved) {
        setButtonState(ButtonState.Disabled)
      }
    }
  }, [buttonState, dirty])

  if (isLoading) {
    return <Spinner size="xl" />
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Heading as="h2" fontSize="14px" fontWeight={600}>
          Automatic Save
        </Heading>
        {isInternal && (
          <Switch isChecked={isAutomaticSaveEnabled} onChange={handleAutomaticSaveChange} />
        )}
      </Flex>
      <Text fontSize="12px" lineHeight="16px">
        {APP_NAME} automatically saves and closes browser tabs that you are not actively using, in
        order to keep your tab bar clean and let you focus on the tabs you need right now. Each tab
        that is automatically saved by {APP_NAME} is listed in the {APP_NAME} saved tabs list.{' '}
      </Text>
      <Text fontSize="12px" lineHeight="16px" mt="16px">
        Use the settings below to adjust default limits if needed.
      </Text>
      <Divider my="24px" />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <AutoSaveSettingsFormContent />

          <Divider my="24px" />
          <Wrap mb="4px" w="100%" align={'center'}>
            <Heading as="h3" fontSize="14px" fontWeight={500}>
              Adaptive Automatic Save <Icon as={HiSparkles} />
            </Heading>
            <Spacer />
            <Flex align="center">
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight={500}
                color="blue"
                mr={2}
                minWidth={'225px'}
              >
                Coming soon - Help shape this feature!
              </Text>
              <Button
                as="a"
                href={FEEDBACK_FORM_URL}
                target="_blank"
                bg="#0071E3"
                color="white"
                _hover={{ bg: '#0071E3', color: 'white' }}
                borderRadius="100px"
                height="28px"
                alignItems="center"
                minWidth={'90px'}
                p="2px 10px 2px 10px"
                gap="6px"
              >
                <Text fontSize="12px" fontWeight="500" whiteSpace="nowrap">
                  Share ideas
                </Text>
              </Button>
            </Flex>
          </Wrap>
          <Text fontSize="12px" lineHeight="16px" color="#585858">
            Using AI, intelligently adjust Automatic Save to be personalized to your unique way of
            browsing and your specific active projects.
          </Text>

          <Flex flexDirection="column" alignItems="flex-end">
            <Button
              mt="24px"
              borderRadius="100px"
              bg="black"
              color="white"
              px="20px"
              isDisabled={buttonState !== ButtonState.Enabled}
              _hover={{ bg: buttonState !== ButtonState.Enabled ? '#000000' : '#585858' }}
              _disabled={{ bg: buttonState === ButtonState.Saved ? '#000000' : '#D5D5D5' }}
              type="submit"
            >
              {buttonState === ButtonState.Saved ? 'Saved!' : 'Save'}
            </Button>
          </Flex>
        </form>
      </FormikProvider>
    </>
  )
}

export default SettingsForm
