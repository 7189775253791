// import 'react-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import WebApp from './webapp'
import {
  ENABLE_POSTHOG,
  POSTHOG_KEY,
  POSTHOG_HOST,
  ENABLE_SENTRY,
  SENTRY_DSN,
  API_SERVER_URL,
} from '../constants'

import { PostHogProvider } from 'posthog-js/react'

if (ENABLE_SENTRY) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [API_SERVER_URL],
  })
}

ReactDOM.createRoot(document.getElementById('app-container') as HTMLElement).render(
  <React.StrictMode>
    {ENABLE_POSTHOG ? (
      <PostHogProvider
        apiKey={POSTHOG_KEY}
        options={{
          api_host: POSTHOG_HOST,
          autocapture: true,
          capture_pageleave: false,
          disable_session_recording: true,
        }}
      >
        <WebApp />
      </PostHogProvider>
    ) : (
      <WebApp />
    )}
  </React.StrictMode>,
)
